import React, { useContext, useState, useRef } from "react";
import VariantBuilderPanelLayersItem from "../variants/VariantBuilderPanelLayersItem.js";
import { VariantContextData } from "../variants/VariantBuilderContext";
import SectionLayer from "../sections/SectionLayer.js";

const ContentToggleLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const element = props.element;
  const content_toggle_item = props.content_toggle_item;
  const row_section = Object.values(
    contextData.objects.variant.sections
  ).filter(
    (section) => section.content_toggle_item_id == content_toggle_item.id
  )[0];

  return (
    <React.Fragment>
      <VariantBuilderPanelLayersItem
        object_type="content_toggle_items"
        object_id={content_toggle_item.id}
        object={content_toggle_item}
        title={`${
          element.element_type == "collapse"
            ? "Collapse"
            : element.element_type == "tabs"
            ? "Tab"
            : element.element_type == "carousel"
            ? "Slide"
            : element.element_type == "conditional_row"
            ? "Row"
            : ""
        } ${content_toggle_item.position}`}
        element={element}
        handleSelect={() => {
          dispatchCustomEvent("selectObject", {
            object_type: "elements",
            object_id: content_toggle_item.element_id,
          });
        }}
        onClickCallback={() => {
          dispatchCustomEvent("updateBuilder", {
            updates: [
              {
                object_type: "elements",
                object_id: element.id,
                setting_name: "[options][editor-visible-toggle-id]",
                value: content_toggle_item.id,
                skip_history: false,
              },
            ],
          });
        }}
        onDuplicateCallback={() => {
          dispatchCustomEvent("updateBuilder", {
            updates: [
              {
                object_type: "elements",
                object_id: element.id,
                setting_name: "[options][editor-visible-toggle-id]",
                value: content_toggle_item.id,
                skip_history: false,
              },
            ],
          });
        }}
        hideMoveCommands={true}
        canvasSelector={`.cf-conditional-row-element[data-element-id="${element.id}"]`}
      >
        <SectionLayer
          section={row_section}
          {...props}
          element={element}
          title="Row"
        />
      </VariantBuilderPanelLayersItem>
    </React.Fragment>
  );
};

export default ContentToggleLayer;
