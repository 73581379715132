import React, { useContext, useState, useRef } from "react";
import VariantBuilderPanelLayersItem from "../variants/VariantBuilderPanelLayersItem.js";
import { VariantContextData } from "../variants/VariantBuilderContext";
import SectionLayer from "../sections/SectionLayer.js";
import ContentToggleLayer from "../content_toggles/ContentToggleLayer.js";
import FieldLayer from "../fields/FieldLayer.js";

const ElementLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const element = props.element;

  return (
    <React.Fragment>
      {element.element_type == "row" && (
        <RowLayer element={element} {...props} title="Row" />
      )}
      {["row"].includes(element.element_type) == false && (
        <VariantBuilderPanelLayersItem
          object_type="elements"
          object_id={element.id}
          title={`${capitalizeFirstLetter(element.element_type.replace('_', ' '))}`}
          element={element}
          element_type={element.element_type}
          canvasSelector={`.cf-element[data-element-id="${element.id}"]`}
          hidden={
            (contextData.device == "desktop" &&
              element.options["hide-desktop"] == "true") ||
            (contextData.device == "mobile" &&
              element.options["hide-mobile"] == "true")
          }
          toggleForDevice={() => {
            builder.update([
              {
                object_type: 'elements',
                object_id: element.id,
                setting_name: `[options][hide-${contextData.device}]`,
                value: `${(element.options[`hide-${contextData.device}`] == "true") == false}`
              },
            ]);
          }}
        >
          {["carousel", "tabs", "collapse", "conditional_row"].includes(element.element_type) && (
            <ContentTogglesLayer element={element} {...props} />
          )}
          {element.element_type == "quiz" && (
            <QuizLayer element={element} {...props} />
          )}
        </VariantBuilderPanelLayersItem>
      )}
    </React.Fragment>
  );
};

const RowLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const element = props.element;

  const row_section = Object.values(contextData.objects.variant.sections).find(
    (section) => section.element_id == element.id
  );

  return row_section ? (
    <SectionLayer section={row_section} element={element} {...props} />
  ) : null;
};

const ContentTogglesLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const element = props.element;

  const content_toggle_items = Object.values(
    contextData.objects.variant.content_toggle_items
  )
    .filter(
      (item) => item.element_id == element.id && item.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  return (
    <React.Fragment>
      <div
        className="w-full layers-container"
        data-object-type="elements"
        data-object-id={element.id}
      >
        {content_toggle_items.map((content_toggle_item) => {
          return (
            <ContentToggleLayer
              key={`content-toggle-${content_toggle_item.id}-layer`}
              element={element}
              content_toggle_item={content_toggle_item}
              {...props}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

const QuizLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const element = props.element;

  const fields = Object.values(contextData.objects.variant.fields)
    .filter(
      (field) =>
        field.element_id == element.id &&
        !field.field_id &&
        field.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  return (
    <React.Fragment>
      <div
        className="w-full layers-container"
        data-object-type="elements"
        data-object-id={element.id}
      >
        {fields.map((field, i) => {
          return (
            <FieldLayer
              key={`field-${field.id}-layer`}
              element={element}
              field={field}
              title={`Question ${i + 1}`}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ElementLayer;
