class ShopifyAdapter {
  constructor(source_product) {
    this.source = 'ShopifyGraphql'
    this.source_product = source_product;

    this.id = this.productId();
    this.product_id = this.variantProductId();
    this.handle = this.productHandle();
    this.available = this.productAvailable();
    this.requires_selling_plan = this.requiresSellingPlan();
    this.title = this.productTitle();
    this.name = this.variantProductName();
    this.description = this.productDescription();
    this.image = this.productImage();
    this.price = this.productPrice();
    this.options = this.productOptions();
    this.variants = this.productVariants() || [];
    this.compare_at_price = this.productCompareAtPrice();
    this.redirect_url = this.productRedirectUrl();
    this.subscriptions = this.productSubscriptions();
    this.imageList = this.productImageList();
    this.collections = this.productCollections();
    this.productType = this.productType();
    this.tags = this.productTags();
    this.variantImages = this.productVariantImages();
    this.selected_options = this.productSelectedOptions();
    this.subscriptions = this.productSubscriptions();
  }

  productId() {
    return this.source_product?.id?.split("/").pop();
  }

  variantProductId() {
    let productID;

    if (this.source_product?.id.includes('ProductVariant')) {
      productID = this.source_product?.product?.id.split("/").pop();
    }

    return productID;
  }

  variantProductName() {
    let productName;

    if (this.source_product?.id.includes('ProductVariant')) {
      productName = this.source_product?.product?.title

      if (this.source_product?.title !== 'Default Title') {
        productName += " - " + this.source_product?.title;
      }
    }

    return productName;
  }

  productHandle() {
    return this.source_product?.handle || this.source_product?.product?.handle;
  }

  productAvailable() {
    return this.source_product?.availableForSale;
  }

  requiresSellingPlan() {
    return this.source_product?.requiresSellingPlan || this.source_product?.product?.requiresSellingPlan || false;
  }

  productTitle() {
    return this.source_product?.title;
  }

  productDescription() {
    return this.source_product?.descriptionHtml;
  }

  productImage() {
    return this.source_product?.featuredImage?.url || this.source_product?.image?.url;
  }

  productImageList() {
    return this.source_product?.images?.edges?.map((f) => f.node).flat(1)
  }

  productSubscriptions() {
    return this.source_product?.sellingPlanGroups?.edges.map((f) => {
      return {
        name: f.node?.name,
        options: f.node?.options,
        sellingPlans: f.node.sellingPlans?.edges?.map((f) => f.node)
      }
    })
  }

  productPrice() {
    return (this.source_product?.priceRange?.minVariantPrice?.amount || this.source_product?.price.amount);
  }

  productCompareAtPrice() {
    if (!this.source_product?.compareAtPrice) return null;

    return this.source_product?.compareAtPrice?.amount * 100;
  }

  productOptions() {
    return this.source_product?.options
  }

  productStockQuantity() {
    return this.source_product?.quantityAvailable || this.source_product?.totalInventory;
  }

  productRedirectUrl() {
    let redirectUrl = `/products/${this.productHandle()}`;

    if (this.source_product?.id.includes('ProductVariant')) {
      redirectUrl += `?variant=${this.productId()}`;
    }

    return redirectUrl;
  }

  productCollections() {
    return this.source_product?.collections?.edges?.map((f) => f.node) || [];
  }

  productType() {
    return this.source_product?.productType
  }

  productTags() {
    return this.source_product?.tags
  }

  productSelectedOptions() {
    return this.source_product?.selectedOptions
  }

  productVariantImages() {
    const uniqueEdges = new Set();

    return this.source_product?.variants?.edges?.reduce((result, edge) => {
      const src = edge.node?.image?.url;

      if (!uniqueEdges.has(src)) {
        uniqueEdges.add(src);
        result.push({
          variant_id: edge.node.id,
          src: src
        });
      }

      return result;
    }, []);
  }

  productSubscriptions() {
    return this.source_product?.sellingPlanGroups?.edges.map((f) => {
      return {
        name: f.node?.name,
        options: f.node?.options,
        sellingPlans: f.node.sellingPlans?.edges?.map((f) => f.node)
      }
    })
  }

  productVariants() {
    return this.source_product?.variants?.edges?.map((f) => {
      return new ShopifyAdapter(f.node);
    });
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.ShopifyAdapter = ShopifyAdapter;
}
