import React, { useState, useEffect } from "react";
import ElementReport from "../elements/ElementReport";

const ContentToggleReport = (props) => {
  const element = props.element;
  const toggle_item = props.toggle_item;
  const [showElements, setShowElements] = useState(false);

  const row_elements = toggle_item.section?.elements
    .filter((el) =>
      ["form", "button", "survey", "quiz", "products", "product_hero"].includes(el.element_type)
    )
    .sort((el) => {
      el.position;
    }) || [];

  return (
    <React.Fragment>
      <div
        className="row hard-center element-selection-report"
        data-element-id={element.id}
        style={{ width: "100%", display: row_elements.length > 0 ? "block" : "none" }}
        data-has-reports={`${row_elements.length > 0}`}
      >
        <div className="row hard-center">
          <div className="col-xs-4">
            <div
              className="text-xl ellipsis field-report-toggle"
              data-toggle-item-id={toggle_item.id}
              style={{
                marginBottom: "5px",
                cursor: "pointer",
              }}
              data-open={`${showElements}`}
              onClick={() => {
                setShowElements(!showElements);
              }}
            >
              {element.element_type == "conditional_row"
                ? `Conditional Row - ${toggle_item.content}`
                : element.element_type == "carousel"
                ? `Carousel ${toggle_item.position}`
                : element.element_type == "tabs"
                ? `Tab ${toggle_item.position}`
                : element.element_type == "collapse"
                ? `Collapse ${toggle_item.position}`
                : `Content Toggle  ${toggle_item.position}`}{" "}
              <span
                className={`glyphicon glyphicon-chevron-${
                  showElements ? "down" : "right"
                } element-selection-report-toggle ml-1`}
              ></span>
            </div>
          </div>
        </div>

        {showElements && (
          <div
            className="row hard-center"
            style={{
              display: "flex",
              width: "100%",
              flexBasis: "100%",
              flexWrap: "wrap",
              flexDirection: "column-reverse",
              backgroundColor: "#fcfdfe",
              marginTop: "2px",
              paddingTop: "15px",
            }}
          >
            <div className="col-xs-12 element-reports">
              {row_elements.map((el) => {
                return (
                  <ElementReport
                    key={`element-${el.id}`}
                    cta={props.cta}
                    step={props.step}
                    element={el}
                    timeframeType={props.timeframeType}
                    range_start={props.range_start}
                    range_end={props.range_end}
                    externalProductValues={
                      ["products", "product_hero"].includes(el.element_type)
                        ? props.externalProductValues.filter(
                            (externalProductValue) =>
                              externalProductValue.slice(-1) == el.id
                          )
                        : []
                    }
                    currency={props.currency}
                    gateReports={props.gateReports}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ContentToggleReport;
