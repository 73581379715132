var websites = {};

import color_picker from "packs/websites/color_picker.js";
websites.color_picker = color_picker;

document.addEventListener("turbolinks:load", function () {
  function brandStyles() {
    ctas.builder.fonts.initialize(function () {
      var brandHeadlineSelect = $(
        'select[name="website[data][brand_font_headline]"], select[data-fonts="true"]'
      );
      var brandHeadlineSelectValue = $(brandHeadlineSelect).attr("data-value");

      builder_fonts_dropdown.forEach((font) => {
        $(brandHeadlineSelect).append(
          `<option value="${font.value}" ${
            font.value == brandHeadlineSelectValue ? "selected" : ""
          }>${font.text}</option>`
        );
      });

      var brandParagraphSelect = $(
        'select[name="website[data][brand_font_paragraph]"]'
      );
      var brandParagraphSelectValue = $(brandParagraphSelect).attr(
        "data-value"
      );

      builder_fonts_dropdown.forEach((font) => {
        $(brandParagraphSelect).append(
          `<option value="${font.value}" ${
            font.value == brandParagraphSelectValue ? "selected" : ""
          }>${font.text}</option>`
        );
      });
    });
  }

  $("#ctaCreate").on("shown.bs.modal", function () {
    brandStyles();
  });

  if (
    $("body").attr("data-controller") == "websites" &&
    $("body").attr("data-action") == "new"
  ) {
    $("#premium_switch").on("click", function () {
      $("#premium_switch_input").click();
    });

    $("#premium_switch_input").on("change", function () {
      var submit_button = $('#new_website input[type="submit"]');
      if ($(this).is(":checked")) {
        var new_text = "+ Add Website";
        $('#website-plan').show();
        
      } else {
        var new_text = "+ Add Website (Free Tier)";
        $('#website-plan').hide();
      }
      $(submit_button).attr("value", new_text);
      $(submit_button).attr("data-disable-with", new_text);
    });
  }

  if (
    $("body").attr("data-controller") == "websites" &&
    $("body").attr("data-action") == "edit"
  ) {
    $(function () {
      $('[data-toggle="popover"]').popover();
    });

    $("#powered_by_switch").click(function () {
      $("#website_powered_by").click();
    });

    $("#website_sub_domain").on("keyup", function () {
      $(this).val(function (_, v) {
        return v.replace(/[^a-zA-Z0-9-]/, "").toLowerCase();
      });
      var subdomain = $(this).val();
      $("#sub-domain").text(subdomain);
    });

    $("#form_tracking_switch").click(function () {
      $("#form_tracking").click();
    });

    $("#form_tracking").on("change", function () {
      console.log($(this).is(":checked"));
      if ($(this).is(":checked")) {
        $("#website_data_form_tracking").val("true");
      } else {
        $("#website_data_form_tracking").val("false");
      }
    });

    $("#google_fonts_switch").click(function () {
      $("#google_fonts").click();
    });

    $("#google_fonts").on("change", function () {
      console.log($(this).is(":checked"));
      if ($(this).is(":checked")) {
        $("#website_data_google_fonts").val("true");
      } else {
        $("#website_data_google_fonts").val("false");
      }
    });

    $("#url_identification_switch").click(function () {
      $("#url_identification").click();
    });

    $("#url_identification").on("change", function () {
      if ($(this).is(":checked")) {
        $("#website_data_url_identification").val("true");
      } else {
        $("#website_data_url_identification").val("false");
      }
    });

    $("#new_owner_email").on("input", function () {
      let owner_email = $(this).val() ? ` (${$(this).val()})` : "";
      $("#website_new_owner_email").html(owner_email);
    });

    $("#transfer-website-form").on("submit", function (e) {
      e.preventDefault();
      $("#transfer-website-form").fadeOut();

      $.ajax({
        url: $(this).attr("action"),
        type: "POST",
        data: $(this).serialize(),
        success: function (data) {
          $("#transfer-website-success").fadeIn("slow");
        },
        error: function (jXHR) {
          $("#transfer-website-error")
            .html(jXHR.responseJSON.error)
            .fadeIn("slow");
        },
      });
    });

    $("#ip_geolocation_switch").click(function () {
      $("#ip_geolocation").click();
    });

    $("#ip_geolocation").on("change", function () {
      if ($(this).is(":checked")) {
        $("#website_data_ip_geolocation").val("true");
      } else {
        $("#website_data_ip_geolocation").val("false");
      }
    });

    if ($("#contact_processing")[0] && $("#contact_processing").is(":checked") == false) {
      $(".website-settings-contact-processing").hide();
    }

    $("#contact_processing").on("change", function (e) {
      if ($(this).is(":checked")) {
        $("#website_data_contact_processing").val("true");
        $(".website-settings-contact-processing").show();
      } else {
        $(".website-settings-contact-processing").hide();
        $("#website_data_contact_processing").val("false");
      }
    });

    $("#contact_processing_switch").click(function () {
      if (
        $("#contact_processing").is(":checked") &&
        confirm(
          "WARNING: This will prevent contact creation and contact integrations from functioning. Are you sure you want to disable contact data processing?"
        )
      ) {
        $("#contact_processing").click();
      } else if ($("#contact_processing").is(":checked") == false) {
        $("#contact_processing").click();
      }
    });

    $("#archive-website").on("click", function () {
      if (
        confirm(
          "Are you sure you want to archive this website and delete its tracked audience?"
        )
      ) {
        window.location.href = "archive";
      }
    });

    $("#restore-website").on("click", function () {
      if (confirm("Are you sure you want to restore this website?")) {
        window.location.href = "restore";
      }
    });

    $("#delete-site").on("click", function () {
      if (
        confirm(
          "DANGER: Are you sure you want to remove this site from this account? This will deactivate the site, and queue all data for deletion"
        )
      ) {
        window.location.href = "remove";
      }
    });

    $("#blacklist-site").on("click", function () {
      if (confirm("DANGER: Are you sure you want to blacklist this site?")) {
        window.location.href = "blacklist";
      }
    });

    $("#unblock-site").on("click", function () {
      if (confirm("DANGER: Are you sure you want to unblock this site? If it was blocked before, it may have been for a good reason.")) {
        window.location.href = "unblock";
      }
    });

    $("#downgrade-site").on("submit", function (e) {
      e.preventDefault();

      if (
        confirm(
          "Are you sure you want to downgrade this site to free? You can upgrade it to premium at any time."
        )
      ) {
        return true;
      }
    });

    $(".currency").each(function (i, select_tag) {
      var value = $(select_tag).attr("data-value");
      if (value) {
        $(select_tag).val(value);
      }
    });

    brandStyles();
  }

  if (
    $("body").attr("data-controller") == "websites" &&
    $("body").attr("data-action") == "insights"
  ) {
    $('[data-toggle="popover"]').popover();
    var website_id = $("body").attr("data-website-id");

    if (!getUrlParameter("insight")) {
      $('.cta-stats, #website-stats[data-premium="true"] .insight-link').click(
        function (e) {
          var target = $(e.target);

          if (
            target.is("a") == false &&
            target.is("select") == false &&
            target.is("span") == false &&
            target.is("button") == false &&
            target.is("input") == false &&
            target.attr("data-toggle") == undefined &&
            $(target).hasClass("not-a-link") == false
          ) {
            window.insight_type = $(this).attr("data-stat");
            setTimeout(function () {
              window.location.href =
                window.location.origin +
                "/websites/" +
                website_id +
                "/insights/?insight=" +
                insight_type;
            }, 500);
          }
        }
      );
    }
  }

  if (
    $("body").attr("data-controller") == "websites" &&
    $("body").attr("data-action") == "index"
  ) {
    // update form action URL
    $('select[name="website_id"]').on("change", function () {
      const accountId = $('#website-upgrade').attr('data-account-id');
      $('#website-upgrade').attr('action', `/accounts/${accountId}/websites/${$(this).val()}`);
    });
  }

  $("#premiumWebsiteModal").on("shown.bs.modal", function () {
    $.getScript("https://fast.wistia.com/embed/medias/iskldcfi5s.jsonp");
  });

  $("#website-nav, #website-nav-close").off('click').on("click", (e) => {
    if (e.target == $("#website-nav")[0]) {
      $("#website-nav").hide();
    }
  });

  $("#website-nav-close").off('click').on("click", (e) => {
    $("#website-nav").hide();
  });

  $('#quick-nav').off('click').on('click', () => {
    $('#website-nav').toggle();
  });

  $('#account-link').off('click');

  if ($('#website-nav').length > 0) {
    $('#account-link').on('click', (e) => {
      e.preventDefault();
      $('#website-nav').toggle();
    });
  }
});

export default websites;
