import React, { useRef, useEffect } from "react";
import Section from "../sections/Section";
import arePropsEqual from "../../utils/arePropsEqual";

const ElementConditionalRow = (props) => {
  let element = props.element;
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    props.variant
  );

  let conditional_row_items = Object.values(element.content_toggle_items)
    .filter(
      (conditional_row) =>
        conditional_row.element_id == element.id &&
        conditional_row.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  const visibleRow =
    Object.values(conditional_row_items).find(
      (r) => r?.id == element.options["editor-visible-toggle-id"]
    ) || conditional_row_items[0];

  const elementRef = React.useRef(null);

  useEffect(() => {
    if (props.builder == false) {
      const matchedRowDiv = elementRef.current.querySelector(
        `.cf-conditional-row-section-box[data-conditional-row-id="${visibleRow.id}"]`
      );

      if (matchedRowDiv) {
        matchedRowDiv.style.display = "block";
      }
    }
  });

  const renderToggleItem = (toggleItem, show = true) => {
    const toggleSection = Object.values(element.sections).filter(
      (section) =>
        section.content_toggle_item_id == toggleItem?.id &&
        section.step_id == props.section.step_id
    )[0];

    if (toggleSection) {
      return (
        <div
          id={`cf-conditional-row-${toggleItem.id}-section-${toggleSection.id}`}
          key={`cf-conditional-row-${toggleItem.id}-section-${toggleSection.id}`}
          className="cf-conditional-row-section-box"
          data-conditional-row-element-id={element.id}
          data-conditional-row-id={toggleItem.id}
          style={{ display: show ? "block" : "none" }}
        >
          <Section
            website={props.website}
            cta={props.cta}
            variant={props.variant}
            builder={props.builder}
            preview={props.preview}
            step={props.step}
            section={toggleSection}
            device={props.device}
          ></Section>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div ref={elementRef} className={classes} data-element-id={element.id}>
        {/* Avoid loading all sections on builder to improve performance */}
        {props.builder == true
          ? renderToggleItem(visibleRow)
          : Object.values(conditional_row_items).map((row_item) => {
              return renderToggleItem(row_item, false);
            })}

        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-conditional-row-element ";
    let alignmentClass = "cf-outer-center";

    if (device == "mobile") {
      if (options["cf-alignment-mobile"]) {
        if (options["cf-alignment-mobile"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment-mobile"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment-mobile"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    } else {
      if (options["cf-alignment"]) {
        if (options["cf-alignment"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element, variant) => {
    let options = { ...element.options };

    // prettier-ignore
    const desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-conditional-row-element {
      }
      `;

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-conditional-row-element {
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default React.memo(ElementConditionalRow, arePropsEqual);
