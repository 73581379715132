import React, { useState, useEffect, useRef } from "react";
import ReportsHelpers from "../../utils/ReportsHelpers";
import ReportCount from "../reports/ReportCount";
import ElementReport from "../elements/ElementReport";
import updateAOV from "../../utils/updateAOV";

const StepReport = (props) => {
  const step = props.step;
  const elements = [];
  const conversionRate = useRef(null);
  const conversionBar = useRef(null);
  const defaultConversionRate = step.conversions
    ? ((step.conversions / step.views) * 100).toFixed(2)
    : 0.0;
  const defaultConversionProgress = (
    (step.conversions / props.firstStep.conversions) *
    100
  ).toFixed(2);

  step.sections.filter((section) => !section.content_toggle_item_id).forEach((section) => {
    section.elements
      .filter(
        (element) =>
          [
            "form",
            "button",
            "survey",
            "products",
            "quiz",
            "product_hero",
            "conditional_row",
            "collapse", 
            "tabs",
            "carousel"
          ].includes(element.element_type) &&
          (element.element_type !== "button" ||
            element.options["disable-conversion"] !== "true" ||
            element.options["confirmation-close"] !== "true")
      )
      .forEach((element) => {
        elements.push(element);
      });
  });

  const updateConversionRate = (
    step,
    conversionRateElement,
    conversionBarElement
  ) => {
    const viewsSpan = $(`#step-${step.id}-views`);
    const conversionsSpan = $(`#step-${step.id}-conversions`);

    const views = parseInt($(viewsSpan).text().replaceAll(",", ""));
    const conversions = parseInt($(conversionsSpan).text().replaceAll(",", ""));

    let conversionRateValue = conversions > 0 ? (conversions / views) * 100 : 0;

    if (conversionRateValue > 100) {
      conversionRateValue = 100.0;
    }

    $(conversionRateElement).text(conversionRateValue.toFixed(2));

    const firstStepConversionsSpan = $(
      `#step-${props.firstStep.id}-conversions`
    );
    const firstStepConversions = parseInt(
      $(firstStepConversionsSpan).text().replaceAll(",", "")
    );
    let conversionBarValue =
      conversions > 0
        ? ((conversions / firstStepConversions) * 100).toFixed(2)
        : 0;

    if (conversionBarValue > 100) {
      conversionBarValue = 100.0;
    }

    $(conversionBarElement)
      .css("width", `${conversionBarValue}%`)
      .attr("aria-valuenow", conversionBarValue);
  };

  return (
    <React.Fragment>
      <div
        className="row hard-center some-vertical-space StepReport"
        style={{
          paddingBottom: "15px",
          borderBottom: "1px solid #E9EBf1",
          minWidth: '765px'
        }}
      >
        <div className="col-xs-12">
          <h4 className="bold">
            Step {step.position}
            {step.options && step.options["name"]
              ? ` - ${step.options["name"]}`
              : ""}
          </h4>
          <p className="light">
            <ReportCount
              inline={true}
              id={`step-${step.id}-views`}
              path={`/websites/${props.cta.website_id}/events_reports`}
              payload={{
                report_type: "distinct_count",
                report: {
                  website_id: props.cta.website_id,
                  event_type: "View",
                  contactable_type: "Cta",
                  contactable_id: props.cta.id,
                  variant_id: step.variant_id,
                  step_id: step.id,
                  range_start: props.range_start,
                  range_end: props.range_end,
                  broadcast_id: null,
                },
              }}
              callback={() => {
                updateConversionRate(
                  step,
                  conversionRate.current,
                  conversionBar.current
                );
              }}
            />{" "}
            people saw this funnel step and{" "}
            <ReportCount
              inline={true}
              id={`step-${step.id}-conversions`}
              path={`/websites/${props.cta.website_id}/events_reports`}
              payload={{
                report_type: "distinct_count",
                report: {
                  website_id: props.cta.website_id,
                  event_type: "Complete",
                  contactable_type: "Cta",
                  contactable_id: props.cta.id,
                  variant_id: step.variant_id,
                  step_id: step.id,
                  range_start: props.range_start,
                  range_end: props.range_end,
                  element_id: null,
                  broadcast_id: null,
                },
              }}
              callback={() => {
                updateConversionRate(
                  step,
                  conversionRate.current,
                  conversionBar.current
                );
              }}
            />{" "}
            converted (
            <span id={`step-${step.id}-conversion-rate`} ref={conversionRate}>
              {props.timeframeType == "All Time"
                ? defaultConversionRate
                : "0.00"}
            </span>
            %).{" "}
            {!props.gateReports && (
              <React.Fragment>
                <ReportCount
                  id={`step-${step.id}-orders`}
                  inline={true}
                  path={`/websites/${props.cta.website_id}/events_reports`}
                  payload={{
                    report_type: "count",
                    report: {
                      website_id: props.cta.website_id,
                      range_start: props.range_start,
                      range_end: props.range_end,
                      event_type: "Order",
                      contactable_type: "Cta",
                      contactable_id: props.cta.id,
                      variant_id: step.variant_id,
                      step_id: step.id,
                      element_id: null,
                      field: null,
                      value: null,
                      product_id: null,
                      broadcast_id: null,
                    },
                  }}
                  callback={() => {
                    updateAOV(
                      `#step-${step.id}-orders`,
                      `#step-${step.id}-revenue`,
                      `#step-${step.id}-aov`
                    );
                  }}
                />{" "}
                orders with an average value of {props.currency}
                <span id={`step-${step.id}-aov`}>0.00</span> (Total:{" "}
                <ReportCount
                  id={`step-${step.id}-revenue`}
                  inline={true}
                  path={`/websites/${props.cta.website_id}/events_reports`}
                  payload={{
                    report_type: "revenue",
                    report: {
                      website_id: props.cta.website_id,
                      range_start: props.range_start,
                      range_end: props.range_end,
                      event_type: "Order",
                      contactable_type: "Cta",
                      contactable_id: props.cta.id,
                      variant_id: step.variant_id,
                      step_id: step.id,
                      element_id: null,
                      field: null,
                      value: null,
                      product_id: null,
                      broadcast_id: null,
                    },
                  }}
                  callback={() => {
                    updateAOV(
                      `#step-${step.id}-orders`,
                      `#step-${step.id}-revenue`,
                      `#step-${step.id}-aov`
                    );
                  }}
                  currency={props.currency}
                />
                )
              </React.Fragment>
            )}
          </p>
        </div>
        <div className="col-xs-12">
          <div
            className="progress"
            style={{ backgroundColor: "#DEE6FD", height: "15px" }}
          >
            <div
              ref={conversionBar}
              className="progress-bar"
              role="progressbar"
              style={{
                width:
                  props.timeframeType == "All Time"
                    ? `${defaultConversionProgress}%`
                    : undefined,
                backgroundColor: "#003FFF",
              }}
              aria-valuenow={
                props.timeframeType == "All Time"
                  ? `${defaultConversionProgress}%`
                  : undefined
              }
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div className="col-xs-12 element-reports">
          {elements.map((element) => {
            return (
              <ElementReport
                key={`element-${element.id}`}
                cta={props.cta}
                step={step}
                element={element}
                timeframeType={props.timeframeType}
                range_start={props.range_start}
                range_end={props.range_end}
                externalProductValues={
                  ["products", "product_hero"].includes(element.element_type)
                    ? props.externalProductValues.filter(
                        (externalProductValue) =>
                          externalProductValue.slice(-1) == element.id
                      )
                    : []
                }
                hide_stats={
                  ["collapse", "tabs", "carousel", "conditional_row"].includes(element.element_type)
                }
                currency={props.currency}
                gateReports={props.gateReports}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StepReport;
