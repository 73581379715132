import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import _, { isEqual, xor } from "lodash";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderCanvasSelect from "../variants/VariantBuilderCanvasSelect";
import ElementText from "../elements/ElementText";
import ElementImage from "../elements/ElementImage";
import ElementButton from "../elements/ElementButton";
import ElementHtml from "../elements/ElementHtml";
import ElementRow from "../elements/ElementRow";
import ElementForm from "../elements/ElementForm";
import ElementSurvey from "../elements/ElementSurvey";
import ElementVideo from "../elements/ElementVideo";
import ElementDivider from "../elements/ElementDivider";
import ElementIcon from "../elements/ElementIcon";
import ElementList from "../elements/ElementList";
import ElementTimer from "../elements/ElementTimer";
import ElementProgress from "./ElementProgress";
import ElementProducts from "./ElementProducts";
import ElementProductHero from "../elements/ElementProductHero";
import ElementCollapse from "./ElementCollapse";
import ElementTabs from "../elements/ElementTabs";
import ElementCarousel from "../elements/ElementCarousel";
import ElementQuiz from "../elements/ElementQuiz";
import ElementSpacingHandle from "../elements/ElementSpacingHandle";
import ElementConditionalRow from "../elements/ElementConditionalRow";
import { isValuePresent, parsedSpacingValue } from "../../utils/builderUtils";

const Element = (props) => {
  let cta = props.cta;
  let element = props.element;
  let options = element.options;
  let classes = style.classes(element, false);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element
  );
  let selected;

  if (props.builder == true) {
    if (
      window.selected_object_type == "elements" &&
      window.selected_object_id == element.id
    ) {
      const [contextData, builder] = useContext(VariantContextData);
      selected = builder.selected();

      if (
        contextData.selected_object_type == "elements" &&
        contextData.selected_object_id == element.id &&
        contextData.selected_object_editing == true
      ) {
        classes = style.classes(element, true);
      }
    }
    useEffect(() => {
      let element_tag = document.querySelector(
        '#builder .cf-element[data-element-id="' + element.id + '"]'
      );

      if (["form", "survey"].includes(element.element_type)) {
        $(element_tag)
          .find(".cf-form-field ")
          .sort(function (a, b) {
            return $(a).attr("data-pos") - $(b).attr("data-pos");
          })
          .prependTo($(element_tag).find(".cf-form-fields"));
      }

      element_tag.querySelectorAll("a").forEach((a) => {
        a.onclick = function (e) {
          e.preventDefault();
        };
      });

      if (
        element.element_type !== "row" &&
        element.element_type !== "collapse" &&
        element.element_type !== "tabs" &&
        element.element_type !== "carousel" &&
        element.element_type !== "conditional_row"
      ) {
        $(element_tag)
          .find(".element-content a")
          .on("click", function (e) {
            e.preventDefault();
          });

        element_tag.onclick = function (e) {
          if (
            window.selected_object_type == "elements" &&
            window.selected_object_id == element.id &&
            ["headline", "text"].includes(element.element_type)
          ) {
            if (
              window.selected_object_editing !== true &&
              !element_tag.querySelector(".fr-inline")
            ) {
              dispatchCustomEvent("selectObject", {
                object_type: "elements",
                object_id: element.id,
                collapse: null,
                editing: true,
                target: e.target,
              });
            }
          } else {
            dispatchCustomEvent("selectObject", {
              object_type: "elements",
              object_id: element.id,
              tab: "Content",
              panel_closed:
                ["headline", "text"].includes(element.element_type) &&
                window.panel_closed == true
                  ? true
                  : false,
            });
          }
        };

        element_tag.ondblclick = function (e) {
          if (
            ["headline", "text", "button"].includes(element.element_type) &&
            !element_tag.querySelector(".fr-inline") &&
            (window.selected_object_type !== "elements" ||
              window.selected_object_id !== element.id ||
              window.selected_object_editing == false)
          ) {
            dispatchCustomEvent("selectObject", {
              object_type: "elements",
              object_id: element.id,
              collapse: null,
              editing: true,
              target: e.target,
              panel_closed:
                ["headline", "text"].includes(element.element_type) &&
                window.panel_closed == true
                  ? true
                  : false,
            });
          }

          if (element.element_type == "html") {
            dispatchCustomEvent("selectObject", {
              object_type: "elements",
              object_id: element.id,
              collapse: null,
              editing: false,
              panel_closed: false,
            });
            let modal_trigger = document.querySelector(
              '.setting[data-setting_type="code"][data-object_type="elements"][data-object_id="' +
                element.id +
                '"] button[setting_name="[html]"]'
            );
            if (modal_trigger) {
              modal_trigger.click();
            }
          }
        };
      }

      if (element.element_type == "row") {
        let row_section = Object.values(element.sections).filter(
          (section) =>
            section.element_id == element.id &&
            section.step_id == props.section.step_id
        )[0];

        if (row_section) {
          element_tag
            .querySelectorAll(
              '.cf-section-overlay[data-element-id="' +
                element.id +
                '"], .cf-background-overlay[data-element-id="' +
                element.id +
                '"]'
            )
            .forEach((row_div) => {
              row_div.onclick = function (e) {
                if (
                  e.target.classList.contains("cf-section-overlay") ||
                  (e.target.classList.contains("cf-background-overlay") &&
                    e.target.getAttribute("data-element-id") == element.id + "")
                ) {
                  let column_name = e.target.parentElement.getAttribute(
                    "data-column-position"
                  );
                  dispatchCustomEvent("selectObject", {
                    object_type: "sections",
                    object_id: row_section.id,
                    collapse: column_name,
                    editing: false,
                  });
                }
              };
            });
        }
      }

      const handleContentToggleItemClick = (elementSelector = undefined) => {
        if (elementSelector) {
          element_tag
            .querySelectorAll(elementSelector)
            .forEach((a) => {
              a.onclick = function (e) {
                e.preventDefault();

                dispatchCustomEvent("selectObject", {
                  object_type: "elements",
                  object_id: element.id,
                });
              };
            });
        }

        element_tag
          .querySelectorAll(
            '.cf-section-overlay[data-element-id="' +
              element.id +
              '"], .cf-background-overlay[data-element-id="' +
              element.id +
              '"]'
          )
          .forEach((row_div) => {
            row_div.onclick = function (e) {
              dispatchCustomEvent("selectObject", {
                object_type: "elements",
                object_id: element.id,
              });

              if (
                e.target.classList.contains("cf-section-overlay") ||
                (e.target.classList.contains("cf-background-overlay") &&
                  e.target.getAttribute("data-element-id") == element.id + "")
              ) {
                dispatchCustomEvent("selectObject", {
                  object_type: "elements",
                  object_id: element.id,
                });
              }
            };
          });
      };

      if (element.element_type == "collapse") {
        handleContentToggleItemClick(`.cf-collapse-toggle[data-list-item-element-id="${element.id}"]`);
      }

      if (element.element_type == "tabs") {
        handleContentToggleItemClick(`.cf-tab-item[data-tab-element-id="${element.id}"]`);
      }

      if (element.element_type == "conditional_row") {
        element_tag.onclick = function (e) {
          if (e.target.classList.contains("cf-cta-item")) {
            e.preventDefault();

            dispatchCustomEvent("selectObject", {
              object_type: "elements",
              object_id: element.id,
            });
          }
        };

        handleContentToggleItemClick();
      }

      if (element.element_type == "carousel") {
        element_tag.onclick = function (e) {
          if (
            e.target.classList.contains("cf-element") ||
            e.target.classList.contains("cf-carousel-container") ||
            e.target.classList.contains("cf-carousel-slide-wrapper") ||
            e.target.classList.contains("cf-section-overlay") ||
            e.target.classList.contains("fa-angle-left") ||
            e.target.classList.contains("fa-angle-right") ||
            e.target.classList.contains("cf-carousel-indicators") ||
            e.target.classList.contains("cf-carousel-indicator")
          ) {
            e.preventDefault();

            dispatchCustomEvent("selectObject", {
              object_type: "elements",
              object_id: element.id,
            });
          }
        };

        element_tag
          .querySelectorAll(
            `.cf-carousel-slide[data-carousel-element-id="${element.id}"]`
          )
          .forEach((a) => {
            a.onclick = function (e) {
              if (e.target.classList.contains("cf-section-overlay")) {
                e.preventDefault();

                dispatchCustomEvent("selectObject", {
                  object_type: "elements",
                  object_id: element.id,
                });
              }
            };
          });
      }

      let selectLabel = element_tag.querySelector(
        '.select-label[data-object_type="elements"][data-object_id="' +
          element.id +
          '"]'
      );
      let selectLabelPopover = element_tag.querySelector(
        '.select-label-popover[data-object_type="elements"][data-object_id="' +
          element.id +
          '"]'
      );

      if (element.element_type !== "row") {
        element_tag.onmouseover = function (e) {
          element_tag.classList.add("editor-select");
          selectLabel.style.display = "block";

          if (selectLabelPopover) {
            selectLabelPopover.style.display = "block";
          }
        };

        element_tag.onmouseleave = function (e) {
          if (false == (window.selected_object_type == "elements" && window.selected_object_id == element.id || element_tag.classList.contains("spacing"))) {
            element_tag.classList.remove("editor-select");
            selectLabel.style.display = "none";

            if (selectLabelPopover) {
              selectLabelPopover.style.display = "none";
            }
          }
        };
      }
    });
  }

  return (
    <React.Fragment>
      <div
        id={"element" + element.id}
        data-id={element.id}
        data-element-id={element.id}
        data-type={element.element_type}
        section-id={element.section_id}
        data-section-id={element.section_id}
        sortable-id={element.column}
        data-sortable-id={element.column}
        data-pos={element.position}
        data-object-type="elements"
        data-object-id={element.id}
        className={
          classes +
          (props.builder == true &&
          selected &&
          selected.object_type == "elements" &&
          selected.object_id == element.id
            ? " editor-select"
            : "")
        }
      >
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
        {(element.element_type == "text" ||
          element.element_type == "headline") && (
          <ElementText {...props}></ElementText>
        )}
        {element.element_type == "image" && (
          <ElementImage {...props}></ElementImage>
        )}
        {element.element_type == "button" && (
          <ElementButton {...props}></ElementButton>
        )}
        {element.element_type == "html" && (
          <ElementHtml {...props}></ElementHtml>
        )}
        {element.element_type == "form" && (
          <ElementForm {...props}></ElementForm>
        )}
        {element.element_type == "survey" && (
          <ElementSurvey {...props}></ElementSurvey>
        )}
        {element.element_type == "video" && (
          <ElementVideo {...props}></ElementVideo>
        )}
        {element.element_type == "divider" && (
          <ElementDivider {...props}></ElementDivider>
        )}
        {element.element_type == "icon" && (
          <ElementIcon {...props}></ElementIcon>
        )}
        {element.element_type == "list" && (
          <ElementList {...props}></ElementList>
        )}
        {element.element_type == "tabs" && (
          <ElementTabs {...props}></ElementTabs>
        )}
        {element.element_type == "carousel" && (
          <ElementCarousel {...props}></ElementCarousel>
        )}
        {element.element_type == "quiz" && (
          <ElementQuiz {...props}></ElementQuiz>
        )}
        {element.element_type == "conditional_row" && (
          <ElementConditionalRow {...props} />
        )}
        {element.element_type == "timer" && (
          <ElementTimer {...props}></ElementTimer>
        )}
        {element.element_type == "progress" && (
          <ElementProgress {...props}></ElementProgress>
        )}
        {element.element_type == "products" && (
          <ElementProducts {...props}></ElementProducts>
        )}
        {element.element_type == "product_hero" && (
          <ElementProductHero {...props}></ElementProductHero>
        )}
        {element.element_type == "collapse" && (
          <ElementCollapse {...props}></ElementCollapse>
        )}
        {element.element_type == "row" && <ElementRow {...props}></ElementRow>}
        {props.builder == true && (
          <VariantBuilderCanvasSelect
            label={element.element_type}
            object_type="elements"
            object_id={element.id}
            object={element}
          />
        )}
        {props.builder == true && (
          <React.Fragment>
            <ElementSpacingHandle key={`element-${element.id}-spacer-top`} {...props} direction="top"></ElementSpacingHandle>
            <ElementSpacingHandle key={`element-${element.id}-spacer-bottom`} {...props} direction="bottom"></ElementSpacingHandle>
            <ElementSpacingHandle key={`element-${element.id}-spacer-left`} {...props} direction="left"></ElementSpacingHandle>
            <ElementSpacingHandle key={`element-${element.id}-spacer-right`} {...props} direction="right"></ElementSpacingHandle>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, editing) => {
    let options = element.options;
    let classes = "cf-cta-item cf-element fr-view";

    if (element.element_type !== "html") {
      if (options["cf-alignment"]) {
        classes += " " + options["cf-alignment"];
      }

      if (element.options["cf-alignment-mobile"]) {
        classes += " " + options["cf-alignment-mobile"];
      }
    }

    if (options["hide-desktop"] == "true") {
      classes += " cf-hide-desktop";
    }

    if (options["hide-mobile"] == "true") {
      classes += " cf-hide-mobile";
    }

    if (options["custom-class"]) {
      classes += " " + options["custom-class"];
    }

    if (editing == true) {
      classes += " editing";
    }

    return classes;
  },

  stylesheet: (builder, device, cta, element) => {
    let options = style.backport(element);

    let element_types_with_space_manually_set = ["list"];
    let not_conditions = element_types_with_space_manually_set
      .map((e) => `:not([data-type='${e}'])`)
      .join("");

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-element[data-element-id='${element.id}']${not_conditions} {
  padding-left: ${isValuePresent(options['padding-left']) == true ? parsedSpacingValue(options['padding-left']) : '0px'} !important;
  padding-right: ${isValuePresent(options['padding-right']) == true ? parsedSpacingValue(options['padding-right']) : '0px'} !important;
  padding-top: ${isValuePresent(options['padding-top']) == true ? parsedSpacingValue(options['padding-top']) : '0px'} !important;
  padding-bottom: ${isValuePresent(options['padding-bottom']) == true ? parsedSpacingValue(options['padding-bottom']) : '0px'} !important;
  margin-left: ${isValuePresent(options['margin-left']) == true ? parsedSpacingValue(options['margin-left']) : '0px'} !important;
  margin-right: ${isValuePresent(options['margin-right']) == true ? parsedSpacingValue(options['margin-right']) : '0px'} !important;
  margin-top: ${isValuePresent(options['margin-top']) == true ? parsedSpacingValue(options['margin-top']) : '0px'} !important;
  margin-bottom: ${isValuePresent(options['margin-bottom']) == true ? parsedSpacingValue(options['margin-bottom']) : '0px'} !important;
}
`;

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-element[data-element-id="${element.id}"]${not_conditions} {
  ${isValuePresent(options['padding-mobile-left']) == true ? 'padding-left: ' + parsedSpacingValue(options['padding-mobile-left']) + '!important;' : ''}
  ${isValuePresent(options['padding-mobile-right']) == true ? 'padding-right: ' + parsedSpacingValue(options['padding-mobile-right']) + '!important;' : ''}
  ${isValuePresent(options['padding-mobile-top']) == true ? 'padding-top: ' + parsedSpacingValue(options['padding-mobile-top']) + '!important;' : ''}
  ${isValuePresent(options['padding-mobile-bottom']) == true ? 'padding-bottom: ' + parsedSpacingValue(options['padding-mobile-bottom']) + '!important;' : ''}
  ${isValuePresent(options['margin-mobile-left']) == true ? 'margin-left: ' + parsedSpacingValue(options['margin-mobile-left']) + '!important;' : ''}
  ${isValuePresent(options['margin-mobile-right']) == true ? 'margin-right: ' + parsedSpacingValue(options['margin-mobile-right']) + '!important;' : ''}
  ${isValuePresent(options['margin-mobile-top']) == true ? 'margin-top: ' + parsedSpacingValue(options['margin-mobile-top']) + '!important;' : ''}
  ${isValuePresent(options['margin-mobile-bottom']) == true ? 'margin-bottom: ' + parsedSpacingValue(options['margin-mobile-bottom']) + '!important;' : ''}
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },

  // converts old database values to current values
  backport: (element) => {
    let options = { ...element.options };
    const levelsMap = {
      0: 0,
      1: 15,
      2: 25,
      3: 40,
      4: 70,
      5: 120,
    };

    ["top", "bottom", "left", "right"].forEach((side) => {
      // desktop
      const desktopKey = "padding-" + side;
      const desktopValue = options[desktopKey];

      if (
        desktopValue &&
        typeof desktopValue == "string" &&
        desktopValue.indexOf("_") > -1
      ) {
        let level = desktopValue.split("_").pop();
        options[desktopKey] = levelsMap[level];
      }

      // mobile
      const mobileKey = "padding-mobile-" + side;
      const mobileValue = options[mobileKey];

      if (
        mobileValue &&
        typeof mobileValue == "string" &&
        mobileValue.indexOf("_") > -1
      ) {
        let level = mobileValue.split("_").pop();
        options[mobileKey] = levelsMap[level];
      }
    });

    if (
      _.isNil(options["padding-top"]) &&
      _.isNil(options["padding-bottom"]) &&
      options["cf-vspace"]
    ) {
      if (options["cf-vspace"] == "cf-little-vspace") {
        options["padding-top"] = 8;
        options["padding-bottom"] = 8;
      }

      if (options["cf-vspace"] == "cf-regular-vspace") {
        options["padding-top"] = 21;
        options["padding-bottom"] = 21;
      }

      if (options["cf-vspace"] == "cf-more-vspace") {
        options["padding-top"] = 32;
        options["padding-bottom"] = 32;
      }
    }

    return options;
  },
};

export default Element;
