var ctas = {
  builder: {},
};

import froalaExtend from "packs/ctas/froala_extend.js";
ctas.builder.froalaExtend = froalaExtend;

import broadcast_page_urls from "packs/ctas/broadcast_page_urls.js";
ctas.broadcast_page_urls = broadcast_page_urls;

import edit from "packs/ctas/edit.js";
ctas.edit = edit;

import fonts from "packs/ctas/fonts.js";
ctas.builder.fonts = fonts;

import stats from "packs/ctas/stats.js";
ctas.stats = stats;

document.addEventListener("turbolinks:load", function () {
  if (
    $("body").attr("data-controller") == "cta" ||
    $("body").attr("data-controller") == "campaign_templates"
  ) {
    if ($("body").attr("data-action") == "index") {
      $("#cta-search select").change(function () {
        $("#cta-search").submit();
      });
      $('#cta-search input[name="name"]').keyup(function () {
        if ($(this).val().length > 0) {
          $(this).parent().find(".search").css("color", "#1892f5");
        } else {
          $(this).parent().find(".search").css("color", "#696969");
        }
      });
      $("#cta-search .search").click(function () {
        $("#cta-search").submit();
      });
    }

    if ($.inArray($("body").attr("data-action"), ["show", "templates", "new"]) > -1) {
      $(".cta-create").on("click", function () {
        var cta_type = $(this).attr("data-cta");
        var cta_size = $(this).attr("data-size");
        var template_cta = $(this).attr("data-template-cta");
        var template_id = $(this).attr("data-template-id");

        if (["templates", "new"].includes($("body").attr("data-action"))) {
          $("#cta_cta_type").val(cta_type);
          $("#cta_" + cta_type + "_type").val(cta_size);
          $("#cta_template").val(template_cta);
          $('#new_cta input[name="template_id"]').val(template_id);
          $("#ctaCreate").modal("show");

          if (!template_id && $('#remix').is(':checked') == false) {
            $("#remix_switch").trigger('click');
            $('#remix').prop('checked', true);
          }

          $("#ctaCreate").on("shown.bs.modal", function () {
            $('#cta_name').focus();
          });
        } else {
          $("#variant_template").val(template_cta);
          $("#new_variant").submit();
        }
      });

      $("#new_cta").on("submit", function () {
        $(this)
          .find('button[type="submit"]')
          .html(
            '<span style="font-size: 12px !important" class="glyphicon glyphicon-refresh spinning"></span> Creating Campaign'
          )
          .attr("disabled", "disabled");
      });

      $("#new_variant").submit(function () {
        $("#newVariant").modal("hide");
      });

      $("#templatePreview").on("hidden.bs.modal", function (e) {
        $("#newVariant").modal("show");
      });

      $("#remix_switch").click(function () {
        $("#remix").click();
      });
  
      $("#remix").on("change", function () {
        if ($(this).is(":checked")) {
          $("#can_remix").val("true");
          $('.remix-settings').show();
        } else {
          $("#can_remix").val("false");
          $('.remix-settings').hide();
        }
      });
    }

    if ($.inArray($("body").attr("data-action"), ["show", "launch"]) > -1) {
      var cta_api_path = window.location.href.replace("launch", "");

      $.getJSON(cta_api_path, function (cta) {
        ctas.edit(cta);

        $("#cta-" + cta.id + "-report")
          .unbind("shown.bs.modal")
          .on("shown.bs.modal", function () {
            if ($("#cta-" + cta.id + "-reports-chart").is(":empty")) {
              var container = $("#cta-" + cta.id + "-reports-container")[0];
              ctas.stats.initialize(container, cta);
            }
          });
      });
    }

    if ($("body").attr("data-action") == "launch") {
      var modal = $("#upgrade");
      var plan_type = window.plan_type || $(modal).attr("data-plan-type");
      var website_premium = $(modal).attr("data-website-premium");

      $(".cta-campaign-tab-toggle").on("click", function () {
        $(".cta-campaign-tab-toggle").removeClass(
          "cta-campaign-tab-toggle-active"
        );
        $(this).addClass("cta-campaign-tab-toggle-active");

        var tab = $(this).attr("data-tab");
        $(".cta-campaign-tab").hide();
        $('.cta-campaign-tab[data-tab="' + tab + '"]').show();
      });

      $("#cta-embed").on("click", function () {
        if (["free", "2023_build"].includes(plan_type) || website_premium == "false") {
          subscriptions.upgrade_modal(
            event,
            false,
            "launch embeds and unlock all campaign formats.",
            "2024_pro",
            "Triggered feature CTA – Embeds"
          );
        } else {
          copyDivToClipboard("cta-embed");
        }
      });
    }

    if ($("body").attr("data-action") == "preview") {
      $("#cta-preview-iframe").on("load", function () {
        this.height = $(window).height() - $(this).offset().top;
      });
    }
  }
});

export default ctas;
