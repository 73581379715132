import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useConditions from "../conditions/useConditions";

const useContentToggle = (Sections) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let variant = contextData.objects.variant;

  const { Conditions } = useConditions();

  const ContentToggleMapping = (elementType, elementId) => {
    const mapping = {
      collapse: {
        title: "Collapse Title",
      },
      tabs: {
        title: "Tab Title",
      },
      carousel: {
        title: "Carousel Title",
      },
      conditional_row: {
        title: "Conditional Row",
      }
    };

    return mapping[elementType];
  };

  const ContentToggle = {
    create: async function (element, options) {
      const elementType = element.element_type;
      const elementTypeMapping = ContentToggleMapping(elementType, element.id);

      let position =
        Object.values(variant.content_toggle_items).filter(
          (list_item) =>
            list_item.element_id == element.id && list_item.toBeDeleted !== true
        ).length + 1;

      builder.create(
        "content_toggle_items",
        {
          position: position,
          element_id: element.id,
          content: `${elementTypeMapping["title"]} ${position}`,
        },
        {
          callback: (content_toggle_item) => {
            let newContextData = { ...contextData };
            newContextData.objects.variant.sections[content_toggle_item.section.id] =
            content_toggle_item.section;
            setContextData(newContextData);

            if (options && options.callback) {
              options.callback(content_toggle_item);
            }
          },
        }
      );
    },

    duplicate: async function (og_toggle_item, payload, skip_history) {
      let attributes = {
        position: og_toggle_item.position,
        content: og_toggle_item.content,
        options: og_toggle_item.options,
        element_id: og_toggle_item.element_id,
        create_initial_section: false,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      // If the field is being duplicated within the same element, we need to increment the position
      if (skip_history !== true) {
        let position =
          Object.values(variant.content_toggle_items).filter(
            (list_item) =>
              list_item.element_id == og_toggle_item.element_id && og_toggle_item.toBeDeleted !== true
          ).length + 1;

        attributes.position = position;
      }

      return new Promise((resolve, reject) => {
        builder.create("content_toggle_items", attributes, {
          callback: async function (toggle_item) {
            try {
              let og_section =
                Object.values(variant.sections).filter(
                  (section) => section.content_toggle_item_id == og_toggle_item.id
                )[0];

              const element = variant.elements[toggle_item.element_id];
              const section = variant.sections[element.section_id];

              let og_conditions = Object.values(variant.conditions).filter(
                (condition) => condition.contactable_id == og_toggle_item.id &&
                  condition.contactable_type == "ContentToggleItem"
              );

              await Promise.all([
                Sections.duplicate(
                  og_section,
                  {
                    element_id: toggle_item.element_id,
                    step_id: section.step_id,
                    content_toggle_item_id: toggle_item.id,
                  },
                  true
                ),
                ...og_conditions.map((condition) => {
                  return Conditions.duplicate(
                    condition,
                    {
                      contactable_id: toggle_item.id,
                    },
                    true
                  );
                })
              ]);

              resolve(toggle_item);
            } catch (error) {
              reject(error);
            }
          },
          skip_history: skip_history,
          skip_select: true,
        });
      });
    },

    remove: (toggle_item) => {
      let message =
        "Are you sure you want to remove this item? Once your changes are saved, it will be irreversibly deleted.";

      builder.remove("content_toggle_items", toggle_item.id, message);
    },

    sortableStart: (list_item) => {
      let containerSelector =
        '.sortable-container[data-sortable-name="content-toggle-list-items"][data-object_type="content_toggle_items"]';
      let container = document.querySelector(containerSelector);

      let item_selector =
        containerSelector +
        " .setting[data-setting_type='item_v2'][data-object_type='content_toggle_items']";

      let sortable_options = {
        sort: true,
        group: { name: "content-list-item-position" },
        animation: 150,
        draggable: item_selector,
        ghostClass: "drop-zone",
        handle: ".item-trigger[data-object_type='content_toggle_items'], .cf-item-v2",
        forceFallback: true,
        fallbackOnBody: true,
        fallbackClass: "builder-sortable-fallback",
        fallbackTolerance: 5,
        delay: 10,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        swapThreshold: 0.8,
        onUpdate: function (event) {
          ContentToggle.sortableUpdate(event, list_item);
        },
      };

      if (container) {
        new Sortable(container, sortable_options);
      }
    },

    sortableUpdate: (event) => {
      let updates = [];

      Array.from(event.to.children).forEach((list_item_div, i) => {
        let new_position = i + 1;
        let object_id = list_item_div.getAttribute("data-object_id");
        if (object_id) {
          let list_item_id = parseInt(object_id);

          updates.push({
            object_type: "content_toggle_items",
            object_id: list_item_id,
            setting_name: "[position]",
            value: new_position,
          });
        }
      });

      builder.update(updates);
    },

    layersSortableStart: (element_id) => {
      let container = document.querySelector(`#layers .layers-container[data-object-type='elements'][data-object-id="${element_id}"]`);

      let sortable_options = {
        sort: true,
        group: { name: "layers-content-toggle-items" },
        animation: 150,
        draggable: `.layers-item[data-object-type='content_toggle_items']`,
        ghostClass: "drop-zone",
        handle: `.layers-item-icon[data-object-type='content_toggle_items'], .layers-item-label[data-object-type='content_toggle_items']`,
        scroll: true,
        scrollSensitivity: 100,
        forceFallback: true,
        fallbackOnBody: true,
        fallbackTolerance: 5,
        fallbackClass: 'hide-during-drag',
        scrollSpeed: 10,
        onUpdate: function (event) {
          ContentToggle.layersSortableUpdate(event);
        },
      };

      if (container) {
        new Sortable(container, sortable_options);
      }
    },

    layersSortableUpdate: (event) => {
      let updates = [];

      Array.from(event.to.children).forEach((list_item_div, i) => {
        let new_position = i + 1;
        let object_id = list_item_div.getAttribute("data-object-id");
        if (object_id) {
          let list_item_id = parseInt(object_id);

          updates.push({
            object_type: "content_toggle_items",
            object_id: list_item_id,
            setting_name: "[position]",
            value: new_position,
          });
        }
      });

      builder.update(updates);
    },
  };

  let currentHook = ContentToggle;
  return { ContentToggle, currentHook };
};

export default useContentToggle;
