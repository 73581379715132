import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import useProductRecommendations from "./useProductRecommendations";
import useProducts from "../products/useProducts";
import ProductSettings from "../products/ProductSettings";
import NewProduct from "../products/NewProduct";
import ConditionsBuilder from "../conditions/ConditionsBuilder";
import variantCustomFieldsArray from "../../utils/variantCustomFieldsArray";

const ProductRecommendationSettings = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const { ProductRecommendations } = useProductRecommendations();
  const { Products } = useProducts();
  const [collapsed, setCollapsed] = useState(
    props.product_recommendation && props.product_recommendation.open
      ? props.product_recommendation.open
      : false
  );

  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let product_recommendation = props.product_recommendation;
  let element = props.element;
  let existing_conditions = Object.values(variant.conditions).filter(
    (condition) =>
      condition.contactable_type == "ProductRecommendation" &&
      condition.contactable_id == product_recommendation.id &&
      condition.toBeDeleted !== true
  );
  let warningVisible = false;

  let object_info = "Conditional Product Recommendation";

  const [shopifyGraphqlLoading, setShopifyGraphqlLoading] = useState(false);

  const productsList = Object.values(variant.products)
  .filter(
    (product) =>
      product.toBeDeleted !== true &&
      product.product_recommendation_id == product_recommendation.id
  )
  .sort((a, b) => {
    return a.position - b.position;
  });

  const [productsDropdown, setProductsDropdown] = useState([
    {
      value: "",
      text: "Select a product",
    },
  ]);
  const [handlesDropdown, setHandlesDropdown] = useState([
    {
      value: "",
      text: "Select a product",
    },
  ]);

  const [collectionsDropdown, setCollectionsDropdown] = useState([
    {
      value: "",
      text: "Select collection",
    },
  ]);

  const [productVariantsDropdown, setProductVariantsDropdown] = useState([
    {
      value: "",
      text: "Select product variant",
    },
  ]);

  let shopifyIntegration = Object.values(website.integrations).filter(
    (i) => i.integration_type == "Shopify" && i.status == true
  )[0];

  useEffect(() => {
    if (["Shopify"].includes(element.options["products-source"])) {
      if (shopifyIntegration) {
        let tempProductsDropdown = [];
        let tempHandlesDropdown = [];
        let tempCollectionsDropdown = [];

        if (shopifyIntegration.api_data["products"]) {
          shopifyIntegration.api_data["products"].forEach((shopifyProduct) => {
            tempProductsDropdown.push({
              value: shopifyProduct["product_id"] + "",
              text: shopifyProduct["name"],
            });

            tempHandlesDropdown.push({
              value: shopifyProduct["id"] + "",
              text: shopifyProduct["name"],
            });
          });
        }

        if (shopifyIntegration.api_data["collections"]) {
          shopifyIntegration.api_data["collections"].forEach(
            (shopifyCollection) => {
              tempCollectionsDropdown.push({
                value: shopifyCollection["id"],
                text: shopifyCollection["name"],
              });
            }
          );
        }

        setProductsDropdown(tempProductsDropdown);
        setHandlesDropdown(tempHandlesDropdown);
        setCollectionsDropdown(tempCollectionsDropdown);

        if (product_recommendation.options["shopify-products-selected"]) {
          updateShopifyProductVariantsDropdown();
        }
      }
    }

    if (["ShopifyGraphql"].includes(element.options["products-source"])) {
      fetchShopifyGraphql();
    }
  }, [element.options["products-source"]]);

  useEffect(() => {
    const handleShopifyProductsChange = () => {
      if (
        shopifyIntegration &&
        element.options["products-source"] == "ShopifyGraphql" &&
        window.cf_shopify_products &&
        Object.values(window.cf_shopify_products).length > 0
      ) {
        fetchShopifyGraphql();
      }
    };

    document.addEventListener("cf_shopify_products_change", handleShopifyProductsChange);

    return () => {
      document.removeEventListener("cf_shopify_products_change", handleShopifyProductsChange);
    };
  }, []);

  const updateShopifyGraphqlProductVariantsDropdown = (products) => {
    let tempProductVariantsDropdown = [];

    products
      .filter((shopifyAdapter) =>
        product_recommendation.options["shopify-products-selected"]
          .split(",")
          .includes(shopifyAdapter.handle)
      )
      .forEach((shopifyAdapter) => {
        shopifyAdapter.variants.forEach((productVariant) => {
          tempProductVariantsDropdown.push({
            value: productVariant.id,
            text: productVariant.title.includes("Default Title") ? shopifyAdapter.title : productVariant.title,
          });
        });
      });

    setProductVariantsDropdown(tempProductVariantsDropdown);
  };

  const updateShopifyProductVariantsDropdown = () => {
    let tempProductVariantsDropdown = [];

    if (shopifyIntegration.api_data["products"]) {
      shopifyIntegration.api_data["products"]
        .filter((shopifyProduct) =>
          product_recommendation.options["shopify-products-selected"]
            .split(",")
            .includes(shopifyProduct.id)
        )
        .forEach((shopifyProduct) => {
          shopifyIntegration.api_data["product_variants"]
            .filter(
              (productVariant) =>
                productVariant.product_id == shopifyProduct.product_id
            )
            .forEach((productVariant) => {
              tempProductVariantsDropdown.push({
                value: productVariant["id"] + "",
                text: productVariant["name"],
              });
            });
        });

      setProductVariantsDropdown(tempProductVariantsDropdown);
    }
  };

  const loadShopifyGraphqlProducts = (products) => {
    let tempProductsDropdown = [];
    let tempHandlesDropdown = [];
    let tempCollectionsDropdown = [];
    let orderedProducts = products.sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    orderedProducts.forEach((shopifyAdapter) => {
      tempProductsDropdown.push({
        value: shopifyAdapter.id,
        text: shopifyAdapter.title,
      });

      tempHandlesDropdown.push({
        value: shopifyAdapter.handle,
        text: shopifyAdapter.title,
      });
    });

    shopifyIntegration.api_data["collections"].forEach((shopifyCollection) => {
      tempCollectionsDropdown.push({
        value: shopifyCollection["id"],
        text: shopifyCollection["name"],
      });
    });

    setProductsDropdown(tempProductsDropdown);
    setHandlesDropdown(tempHandlesDropdown);
    setCollectionsDropdown(tempCollectionsDropdown);

    if (product_recommendation.options["shopify-products-selected"]) {
      updateShopifyGraphqlProductVariantsDropdown(orderedProducts);
    }
  };

  const fetchShopifyGraphql = () => {
    setShopifyGraphqlLoading(true);

    const cached_products = window.cf_shopify_products;

    if (cached_products) {
      loadShopifyGraphqlProducts(cached_products);
      setShopifyGraphqlLoading(false);
      return;
    }

    if (shopifyIntegration) {
      const parsed_store_name = (
        shopifyIntegration.api_data?.api_url ||
        shopifyIntegration.api_data?.website
      )
        ?.replace("http://", "")
        ?.replace("https://", "")
        ?.replace(".myshopify.com", "")
        ?.replace("/", "")
        ?.replace(" ", "");
      const shopifyClient = new ShopifyGraphqlClient({
        store_name: parsed_store_name,
        token: shopifyIntegration.api_data["storefront-token"],
      });

      new ProductsAdapterService(shopifyClient, "ShopifyGraphql")
        .getProducts({ sort: element.options["shopify-products-sorting"] })
        .then((response) => {
          window.cf_shopify_products = response;
          loadShopifyGraphqlProducts(response);
        })
        .finally(() => setShopifyGraphqlLoading(false));
    }
  };

  if (existing_conditions.length == 0) {
    warningVisible = true;
  }

  if (
    ["ShopifyGraphql", "Shopify"].includes(element.options["products-source"])
  ) {
    if (
      !product_recommendation.options["shopify-products-source"] ||
      product_recommendation.options["shopify-products-source"] == "cart"
    ) {
      object_info = "Products Added To Cart";
    }

    if (
      product_recommendation.options["shopify-products-source"] == "collection"
    ) {
      object_info = "Collection";
      if (product_recommendation.options["shopify-products-collection"]) {
        object_info += ` - ${product_recommendation.options["shopify-products-collection"]}`;
      } else {
        warningVisible = true;
      }
    }

    if (
      product_recommendation.options["shopify-products-source"] == "related"
    ) {
      object_info = "Related Products";
    }

    if (product_recommendation.options["shopify-products-source"] == "filter") {
      object_info = "Filter";
      if (product_recommendation.options["shopify-products-filter"]) {
        object_info += ` - ${product_recommendation.options["shopify-products-filter"]}`;
      } else {
        warningVisible = true;
      }
    }

    if (
      product_recommendation.options["shopify-products-source"] == "selected"
    ) {
      object_info = "Select Products";
      if (product_recommendation.options["shopify-products-selected"]) {
        object_info +=
          product_recommendation.options["shopify-products-selected"];
      } else {
        warningVisible = true;
      }
    }

    if (
      product_recommendation.options["shopify-products-source"] == "current"
    ) {
      object_info = "Current Product Page";
    }

    if (
      product_recommendation.options["shopify-products-source"] == "filter_quiz_answers"
    ) {
      object_info = "Quiz Auto Recommendation";
    }
  }

  const customFields = variantCustomFieldsArray(website, variant, contextData, true);

  const showFilterOperator = () => {
    return typeof product_recommendation.options["shopify-products-filter"] == "string" &&
      product_recommendation.options["shopify-products-filter"].length > 0 &&
      (product_recommendation.options["shopify-products-filter"].indexOf(',') > -1 || product_recommendation.options["shopify-products-filter"].indexOf('{') > -1) && 
      ["products", "product_hero"].includes(element.element_type) &&
      shopifyIntegration !== undefined &&
      ["ShopifyGraphql"].includes(element.options["products-source"]) &&
      product_recommendation.options["shopify-products-source"] == "filter"
  }

  const showVariantFilterOperator = () => {
    return typeof product_recommendation.options["products-variant-filter"] == "string" &&
      product_recommendation.options["products-variant-filter"].length > 0 &&
      (product_recommendation.options["products-variant-filter"].indexOf(',') > -1 || product_recommendation.options["products-variant-filter"].indexOf('{') > -1) && 
      ["products", "product_hero"].includes(element.element_type) &&
      shopifyIntegration !== undefined &&
      ["ShopifyGraphql"].includes(element.options["products-source"]) &&
      product_recommendation.options["shopify-products-source"] == "filter"
  }

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={product_recommendation}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="product_recommendations"
        object_id={product_recommendation.id}
        title={
          product_recommendation.options["label"]
            ? product_recommendation.options["label"]
            : object_info
        }
        modalTitle={`Product Recommendation - ${
          product_recommendation.options["label"]
            ? product_recommendation.options["label"]
            : object_info
        }`}
        style={{ margin: "4px 0px" }}
        label_max_length={25}
        label_hover_max_length={14}
        panel_type="modal"
        warning={warningVisible ? "Product filter is incomplete" : ""}
        warningBorder={false}
        label_icon={
          element.options["products-source"] == "Shopify"
            ? "fab fa-shopify"
            : "fas fa-shopping-cart"
        }
        duplicate_payload={{
          element_id: element.id,
        }}
        callback={() => {
          if (
            !["ShopifyGraphql", "Shopify"].includes(
              element.options["products-source"]
            )
          ) {
            Products.sortableStart(null, product_recommendation);
          }
        }}
      >
        <VariantBuilderSetting
          object={product_recommendation}
          setting_name="[options][label]"
          setting_type="text"
          object_type="product_recommendations"
          object_id={product_recommendation.id}
          label={`Label this product recommendation`}
          value={
            product_recommendation.options["label"]
              ? product_recommendation.options["label"]
              : ""
          }
          placeholder="Write label here"
          update_on_input_end={true}
        />

        <div className="col-xs-12">
          <div className="flex my-5 relative">
            <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
              <div className="w-full mt-[13px]">If</div>
              <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
            </div>

            <div className="w-[560px]">
              <ConditionsBuilder
                cta={cta}
                variant={variant}
                website={website}
                contactable_type="ProductRecommendation"
                contactable_id={product_recommendation.id}
                dark_mode={contextData.dark_mode}
                builder={true}
                hide_delete_button={true}
              />
            </div>
          </div>
        </div>

        <div className="col-xs-12">
          <div className="flex my-5 relative">
            <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
              <div className="w-full mt-[13px]">Then</div>
              <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
            </div>

            <div className="w-[560px]">
              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[integrations-modal]"
                setting_type="html"
                object_type="elements"
                object_id={product_recommendation.id}
                condition={
                  (shopifyIntegration == undefined || shopifyIntegration.api_data["storefront-token"] == undefined) &&
                  element.options["products-source"] == "ShopifyGraphql"
                }
              >
                <div
                  className="row hard-center p-[15px] integration-first-message bg-slate-100 rounded dark:bg-slate-800"
                  data-element-id={product_recommendation.id}
                >
                  <p className="font-bold text-xl text-black dark:text-white">
                    Activate Shopify headless source
                  </p>
                  <p className="text-lg text-black dark:text-white">
                    { shopifyIntegration == undefined && 'Integrate Shopify to display your products.'}
                    { shopifyIntegration && shopifyIntegration.api_data["storefront-token"] == undefined && 'The Shopify headless source requires new permissions.'}
                  </p>
                  <button
                    className="text-lg px-3 py-2 font-bold text-white bg-black dark:bg-black border-transparent rounded-lg"
                    data-toggle="modal"
                    data-target="#integrations"
                  >
                    Connect →
                  </button>
                </div>
              </VariantBuilderSetting>


              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][products]"
                setting_type="html"
                condition={
                  !["ShopifyGraphql", "Shopify"].includes(
                    element.options["products-source"]
                  )
                }
                warning={
                  !["ShopifyGraphql", "Shopify"].includes(
                    element.options["products-source"]
                  ) && productsList.length == 0
                    ? "No products added. Add below"
                    : undefined
                }
              >
                <div
                  className="row products-list"
                  data-object_type="product_recommendations"
                  data-object_id={product_recommendation.id}
                >
                  {Object.values(productsList).map((product, i) => {
                    return (
                      <React.Fragment key={"product-settings-" + product.id}>
                        <ProductSettings product={product} {...props} />
                      </React.Fragment>
                    );
                  })}
                </div>

                <NewProduct
                  product_recommendation={product_recommendation}
                />
              </VariantBuilderSetting>

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-source]"
                setting_type="dropdown"
                label="Shopify Products Filter"
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                value={
                  product_recommendation.options["shopify-products-source"]
                    ? product_recommendation.options["shopify-products-source"]
                    : undefined
                }
                options={[
                  { value: "", text: "Select Filter", disabled: true, selected: true },
                  { value: "cart", text: "Products Added To Cart", condition: element.element_type == "products" },
                  { value: "collection", text: "Products In Collection", condition: element.element_type == "products" },
                  { value: "filter", text: "Products Filter" },
                  { value: "selected", text: "Select Products" },
                  { value: "current", text: "Current Product Page" },
                  { value: "filter_quiz_answers", text: "Quiz Auto Recommendation", condition: element.options["products-source"] == "ShopifyGraphql" },
                  { value: "related", text: "Related To A Product", condition: element.element_type == "products" },
                ]}
                condition={
                  ["products", "product_hero"].includes(element.element_type) &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(
                    element.options["products-source"]
                  )
                }
                loading={
                  product_recommendation.options["shopify-products-source"] ==
                    "ShopifyGraphql" && shopifyGraphqlLoading
                }
                feature_gate_type="Upsells & Cross-sells"
              />

              <VariantBuilderSetting
                object={product_recommendation}
                object_id={product_recommendation.id}
                setting_name="[options][products-filter-quiz-answers-operator]"
                setting_type="options_buttons"
                object_type="product_recommendations"
                label="Product Name Or Tags Must Match"
                options={
                  [
                    { value: "OR", title: "ANY Answer", height: 40 },
                    { value: "AND", title: "ALL Answers", height: 40 },
                  ]
                }
                condition={
                  shopifyIntegration !== undefined &&
                  element.options["products-source"] == "ShopifyGraphql" &&
                  product_recommendation.options["shopify-products-source"] == "filter_quiz_answers"
                }
                value={product_recommendation.options["products-filter-quiz-answers-operator"]}
                placeholder={"OR"}
              />

              <VariantBuilderSetting
                object={product_recommendation}
                object_id={product_recommendation.id}
                label="Exclude Questions From Auto Filter"
                setting_name="[options][products-filter-quiz-answers-excluded-fields]"
                setting_type="select"
                multi_select={true}
                object_type="product_recommendations"
                options={customFields}
                condition={
                  shopifyIntegration !== undefined &&
                  element.options["products-source"] == "ShopifyGraphql" &&
                  product_recommendation.options["shopify-products-source"] == "filter_quiz_answers"
                }
                value={product_recommendation.options["products-filter-quiz-answers-excluded-fields"]}
                placeholder={"Choose questions to exclude"}
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-related-type]"
                setting_type="dropdown"
                label="Show Products Related To"
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                value={
                  product_recommendation.options[
                    "shopify-products-related-type"
                  ]
                    ? product_recommendation.options[
                        "shopify-products-related-type"
                      ]
                    : "current"
                }
                options={[
                  { value: "current", text: "Current Product Page" },
                  { value: "cart", text: "First Product In Cart" },
                  { value: "order", text: "First Product In Order" },
                  { value: "manual", text: "Manual Product Selection" },
                ]}
                condition={
                  element.element_type == "products" &&
                  shopifyIntegration !== undefined &&
                  ["Shopify", "ShopifyGraphql"].includes(
                    element.options["products-source"]
                  ) &&
                  product_recommendation.options["shopify-products-source"] ==
                    "related"
                }
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-collection]"
                setting_type="select"
                label={"Collection"}
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                options={collectionsDropdown}
                value={
                  product_recommendation.options["shopify-products-collection"]
                    ? product_recommendation.options[
                        "shopify-products-collection"
                      ]
                    : ""
                }
                condition={
                  element.element_type == "products" &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(
                    element.options["products-source"]
                  ) &&
                  product_recommendation.options["shopify-products-source"] ==
                    "collection"
                }
                multi_select={true}
                warning={
                  !product_recommendation.options["shopify-products-collection"]
                    ? "A selected collection is required"
                    : undefined
                }
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-related-default]"
                setting_type="select"
                label={
                  ["cart", "current", "order", undefined].includes(
                    product_recommendation.options[
                      "shopify-products-related-type"
                    ]
                  )
                    ? "Fallback Product"
                    : "Show Products Related To"
                }
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                options={productsDropdown}
                value={
                  product_recommendation.options[
                    "shopify-products-related-default"
                  ]
                    ? product_recommendation.options[
                        "shopify-products-related-default"
                      ]
                    : ""
                }
                condition={
                  ["products", "product_hero"].includes(element.element_type) &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(
                    element.options["products-source"]
                  ) &&
                  product_recommendation.options["shopify-products-source"] ==
                    "related"
                }
                warning={
                  !product_recommendation.options[
                    "shopify-products-related-default"
                  ] &&
                  product_recommendation.options[
                    "shopify-products-related-type"
                  ] == "manual"
                    ? "A product selection is required"
                    : undefined
                }
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-related-intent]"
                setting_type="dropdown"
                label="Product Relation Intent"
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                value={
                  product_recommendation.options[
                    "shopify-products-related-intent"
                  ]
                    ? product_recommendation.options[
                        "shopify-products-related-intent"
                      ]
                    : "related"
                }
                options={[
                  { value: "related", text: "Related" },
                  {
                    value: "complementary",
                    text: "Complementary (Search & Discovery app)",
                  },
                ]}
                condition={
                  element.element_type == "products" &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(
                    element.options["products-source"]
                  ) &&
                  product_recommendation.options["shopify-products-source"] ==
                    "related"
                }
                tooltip="Related: Products that are similar to the product being viewed. <br><br>Complementary: Products that are often purchased together with the product used for the recommendation, which must be configured using the official Shopify Search & Discovery app."
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-filter]"
                setting_type="text"
                label="Filter Query"
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                value={
                  product_recommendation.options["shopify-products-filter"]
                    ? product_recommendation.options["shopify-products-filter"]
                    : ""
                }
                placeholder={element.options["products-source"] == "ShopifyGraphql" ? "Comma separated keywords" : "Name or tag"}
                note={element.options["products-source"] == "ShopifyGraphql" ? "Tip: Filter by answers using merge tags like: {pet_type}" : ""}
                note_full_width={true}
                condition={
                  ["products", "product_hero"].includes(element.element_type) &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(element.options["products-source"])
                  && product_recommendation.options["shopify-products-source"] == "filter"
                }
                warning={
                  !product_recommendation.options["shopify-products-filter"]
                    ? "A products filter is required"
                    : undefined
                }
                col={showFilterOperator() ? "8" : "12"}
              />

              <VariantBuilderSetting
                object={product_recommendation}
                object_id={product_recommendation.id}
                setting_name="[options][products-filter-operator]"
                setting_type="options_buttons"
                label="Operator"
                object_type="product_recommendations"
                options={
                  [
                    { value: "OR", title: "OR" },
                    { value: "AND", title: "AND" },
                  ]
                }
                condition={showFilterOperator()}
                value={element.options["products-filter-operator"]}
                placeholder={"OR"}
                col="4"
                classNames="no-padding-left"
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][products-variant-filter]"
                setting_type="text"
                label="Variant Filter Query"
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                value={product_recommendation.options["products-variant-filter"]}
                placeholder="Comma separated keywords"
                note="Tip: Filter by answers using merge tags like {pet_type}"
                note_full_width={true}
                condition={
                  ["products", "product_hero"].includes(element.element_type) &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql"].includes(element.options["products-source"]) &&
                  product_recommendation.options["shopify-products-source"] == "filter"
                }
                col={showVariantFilterOperator() ? "8" : "12"}
              />

              <VariantBuilderSetting
                object={product_recommendation}
                object_id={product_recommendation.id}
                setting_name="[options][products-variant-filter-operator]"
                setting_type="options_buttons"
                label="Operator"
                object_type="product_recommendations"
                options={
                  [
                    { value: "OR", title: "OR" },
                    { value: "AND", title: "AND" },
                  ]
                }
                condition={showVariantFilterOperator()}
                value={element.options["products-variant-filter-operator"]}
                placeholder={"OR"}
                col="4"
                classNames="no-padding-left"
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-current-fallback]"
                setting_type="select"
                label={"Fallback Product"}
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                options={handlesDropdown}
                value={
                  product_recommendation.options[
                    "shopify-products-current-fallback"
                  ]
                    ? product_recommendation.options[
                        "shopify-products-current-fallback"
                      ]
                    : ""
                }
                condition={
                  ["products", "product_hero"].includes(element.element_type) &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(element.options["products-source"])
                  && (["current", "filter_quiz_answers"].includes(product_recommendation.options["shopify-products-source"]) ||
                    element.element_type == "product_hero" && product_recommendation.options["shopify-products-source"] == "filter"
                  )
                }
                warning={
                  !product_recommendation.options[
                    "shopify-products-current-fallback"
                  ]
                    ? "A fallback product is required"
                    : undefined
                }
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-unavailable]"
                setting_type="checkbox"
                label="Show Unavailable Products"
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                value={
                  product_recommendation.options["shopify-products-unavailable"]
                    ? product_recommendation.options[
                        "shopify-products-unavailable"
                      ]
                    : "false"
                }
                condition={
                  element.element_type == "products" &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(
                    element.options["products-source"]
                  ) &&
                  ["collection"].includes(
                    product_recommendation.options["shopify-products-source"]
                  )
                }
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-selected]"
                setting_type="select"
                label={
                  element.element_type == "products"
                    ? "Products To Display"
                    : "Product To Display"
                }
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                value={
                  product_recommendation.options["shopify-products-selected"]
                    ? product_recommendation.options[
                        "shopify-products-selected"
                      ]
                    : ""
                }
                options={handlesDropdown}
                condition={
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(element.options["products-source"])
                  && product_recommendation.options["shopify-products-source"] == "selected"
                }
                multi_select={element.element_type == "products" ? true : false}
                warning={
                  !product_recommendation.options["shopify-products-selected"]
                    ? "Product selection is required"
                    : undefined
                }
                callback={() => {
                  if (
                    product_recommendation.options["products-source"] ==
                    "ShopifyGraphql"
                  ) {
                    updateShopifyGraphqlProductVariantsDropdown(
                      window.cf_shopify_products
                    );
                  } else {
                    updateShopifyProductVariantsDropdown();
                  }
                }}
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-selected-variants]"
                setting_type="select"
                label="Limit To Product Variants"
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                value={
                  product_recommendation.options[
                    "shopify-products-selected-variants"
                  ]
                    ? product_recommendation.options[
                        "shopify-products-selected-variants"
                      ]
                    : ""
                }
                options={productVariantsDropdown}
                condition={
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql", "Shopify"].includes(element.options["products-source"])
                  && product_recommendation.options["shopify-products-source"] == "selected"
                }
                multi_select={true}
              />

              <VariantBuilderSetting
                object={product_recommendation}
                setting_name="[options][shopify-products-sorting]"
                setting_type="dropdown"
                label={"Product Sorting"}
                object_type="product_recommendations"
                object_id={product_recommendation.id}
                options={[
                  { value: "", text: "Title" },
                  { value: "BEST_SELLING", text: "Best Selling" },
                  { value: "CREATED_AT", text: "Created At" },
                  { value: "UPDATED_AT", text: "Updated At" },
                  { value: "PRICE_ASC", text: "Cheapest" },
                  { value: "PRICE_DESC", text: "Most Expensive" },
                  { value: "PRODUCT_TYPE", text: "Product Type" },
                  { value: "VENDOR", text: "Vendor" },
                ]}
                value={
                  product_recommendation.options["shopify-products-sorting"]
                }
                condition={
                  element.element_type == "products" &&
                  shopifyIntegration !== undefined &&
                  ["ShopifyGraphql"].includes(
                    element.options["products-source"]
                  ) &&
                  ["collection", "related", "filter", "selected"].includes(
                    product_recommendation.options["shopify-products-source"]
                  )
                }
              />
            </div>
          </div>
        </div>
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default ProductRecommendationSettings;
