import React from "react";
import useContentToggle from "./useContentToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewContentToggleItem = (props) => {
  const { ContentToggle } = useContentToggle();
  const element = props.element;

  const createListItem = () => {
    ContentToggle.create(element, {
      callback: (content_toggle_item) => {
        if (props.callback) {
          props.callback(content_toggle_item);
        }

        dispatchCustomEvent("focusItem", {
          parent_id: props.element.id,
          item_type: "content_toggle_items",
          item_id: content_toggle_item.id,
        });
      },
    });
  };

  const buttonStyleClass = (style) => {
    const buttonDefault =
      "w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white";

    const buttonWhite =
      "bg-white dark:bg-slate-700 text-lg text-black dark:text-white py-2 px-4 text-center rounded-lg border-solid border-[1px] border-slate-200 dark:border-slate-700";

    if (style == "white") {
      return buttonWhite;
    }
    return buttonDefault;
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 my-5">
        <button
          onClick={createListItem}
          className={buttonStyleClass(props.button_style)}
        >
          {props.button_style == "white" ? (
            <>
              <FontAwesomeIcon icon="fas fa-plus" className="mr-1" /> Add{" "}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" />{" "}
              Add{" "}
            </>
          )}

          {`${
            element.element_type == "collapse"
              ? "Collapse"
              : element.element_type == "tabs"
              ? "Tab"
              : element.element_type == "carousel"
              ? "Slide"
              : element.element_type == "conditional_row"
              ? "Conditional Row"
              : "Item"
          }`}
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewContentToggleItem;
