import React, { useContext, useEffect, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import htmlToLabel from "../../utils/htmlToLabel";
import ConditionsBuilder from "../conditions/ConditionsBuilder";

const ContentToggleConditionalRowSettings = (props) => {
  const [contextData] = useContext(VariantContextData);

  const toggle_item = props.toggle_item;
  const element = contextData.objects.variant.elements[toggle_item.element_id];

  let cta = contextData.objects.cta;
  let website = contextData.objects.website;
  let variant = contextData.objects.variant;

  // Make sure the content has a wrapped tag when calling `text`
  let toggle_item_content = htmlToLabel(toggle_item.content);

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={toggle_item}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="content_toggle_items"
        object_id={toggle_item.id}
        title={toggle_item_content}
        label_max_length={25}
        label_hover_max_length={15}
        style={{
          marginBottom: "0px",
        }}
        position={toggle_item.position ? toggle_item.position : ""}
        hide_handle={true}
        force_highlight={props.visible_row_id == toggle_item.id}
        duplicate_payload={{
          content: `${toggle_item.content} (Copy)`,
        }}
        callbackOnDuplicate={(e) => {
          setTimeout(() => {
            props.update_visible_row(e.id);

            dispatchCustomEvent("focusItem", {
              parent_id: element.id,
              item_type: "content_toggle_items",
              item_id: e.id,
            });

          }, 500);
        }}
        onEdit={() => {
          props.update_visible_row(toggle_item.id);

          const rowSection = Object.values(contextData.objects.variant.sections).find(
            (section) => section.content_toggle_item_id == toggle_item.id
          );

          dispatchCustomEvent("selectObject", {
            object_type: "sections",
            object_id: rowSection.id,
            collapse: 1,
            editing: false,
          });
        }}
        custom_button={() => {
          return (
            <button
              title="Edit Conditions"
              className={`w-[25px] h-[25px] p-0 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
              onClick={() => {
                if (contextData.selected_item_id == toggle_item.id) {
                  dispatchCustomEvent("focusItem", {
                    parent_id: element.id,
                    item_type: "content_toggle_items",
                    item_id: null,
                  });
                }

                setTimeout(() => {
                  dispatchCustomEvent("focusItem", {
                    parent_id: element.id,
                    item_type: "content_toggle_items",
                    item_id: toggle_item.id,
                  });
                }, 150);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M1.875 11.4578H3.6217C4.30293 11.4578 4.94222 11.1288 5.33817 10.5745L5.93786 9.73493M12.6889 4.04485H10.9422C10.261 4.04485 9.62173 4.37384 9.22578 4.92817L8.55469 5.8677M11.659 2.5L13.125 4.04485L11.659 5.5897M11.659 9.9478L13.125 11.4927L11.659 13.0375M1.875 4.28336H3.6217C4.30293 4.28336 4.94222 4.61235 5.33817 5.16668L9.22578 10.6093C9.62173 11.1637 10.261 11.4927 10.9422 11.4927H12.6889"
                  stroke="black"
                />
              </svg>
            </button>
          );
        }}
      >
        <VariantBuilderSetting
          object={toggle_item}
          object_id={toggle_item.id}
          setting_name="[content]"
          setting_type="text"
          object_type="content_toggle_items"
          label={`Label`}
          value={toggle_item.content ? toggle_item.content : ""}
          placeholder="Write label here"
          update_on_input_end={true}
        />

        <div className="col-xs-12">
          <div className="flex my-5 relative">
            <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
              <div className="w-full mt-[13px]">If</div>
              <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
            </div>

            <div className="w-[560px]">
              <ConditionsBuilder
                cta={cta}
                variant={variant}
                website={website}
                contactable_type="ContentToggleItem"
                contactable_id={toggle_item.id}
                dark_mode={contextData.dark_mode}
                builder={true}
              />
            </div>
          </div>
        </div>
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default ContentToggleConditionalRowSettings;
