import React, { useContext, useState, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import NewContentToggleItem from "../content_toggles/NewContentToggleItem";
import ContentToggleConditionalRowSettings from "../content_toggles/ContentToggleConditionalRowSettings";
import Tooltip from "../shared/Tooltip";

const ElementSettingsConditionalRow = (props) => {
  const wistia = useRef(null);
  const [contextData, builder] = useContext(VariantContextData);
  const website = contextData.objects.website;
  const subscription = contextData.objects.subscription;

  let variant = contextData.objects.variant;
  let element = builder.selected().object;

  let conditional_row_items = Object.values(variant.content_toggle_items)
    .filter(
      (conditional_row) =>
        conditional_row.element_id == element.id &&
        conditional_row.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  const [visibleRowId, setVisibleRowId] = useState(
    Object.values(conditional_row_items).find((c) => c?.id == element.options["editor-visible-toggle-id"])
      ? element.options["editor-visible-toggle-id"]
      : conditional_row_items[0]?.id
  );

  const updateVisibleRow = (target_row_id) => {
    let updates = [
      {
        object_type: "elements",
        object_id: element.id,
        setting_name: "[options][editor-visible-toggle-id]",
        value: target_row_id,
        skip_history: false,
      },
    ];

    dispatchCustomEvent("updateBuilder", { updates: updates });
    setVisibleRowId(target_row_id);
  };

  useEffect(() => {
    conditional_row_items.forEach((conditional_row_item) => {
      $(`.cf-item-box[data-object-id="${conditional_row_item.id}"]`)
        .off("click")
        .on("click", () => {
          updateVisibleRow(conditional_row_item.id);
        });
    });
  });

  return (
    <React.Fragment>
      <div className="col-xs-12">
        <div className="w-full relative text-black dark:text-white">
          <span className="font-semibold py-3 text-xl">Conditional Rows:</span>

          <Tooltip
            label="Learn"
            tooltip={`The first row to have conditions met will display.`}
          />
        </div>
      </div>

      <div
        className="sortable-container col-xs-12"
        data-sortable-name="content-toggle-list-items"
        data-object_type="content_toggle_items"
      >
        {conditional_row_items.map((conditional_row_item, i) => {
          return (
            <ContentToggleConditionalRowSettings
              visible_row_id={visibleRowId}
              update_visible_row={updateVisibleRow}
              key={"content_toggle_item-" + conditional_row_item.id}
              toggle_item={conditional_row_item}
              {...props}
            />
          );
        })}
      </div>

      <NewContentToggleItem
        element={element}
        button_style="white"
        callback={(created_toggle_item) => {
          updateVisibleRow(created_toggle_item.id);
        }}
      />

      {(['free', '2024_pages', '2024_core', '2024_pro'].includes(subscription.plan_type) || ['2024_pages', '2024_core', '2024_pro'].includes(website.plan_type) || website.premium !== true) && (
        <div className="row absolute top-0 bottom-0 right-0 left-0 w-auto h-full p-5" style={{
          backdropFilter: 'blur(4px)'
        }}>
          <div className="row hard-center mt-5 mb-3">
            <div className="col-xs-12 !float-right" style={{ marginTop: "0px", maxWidth: '250px' }}>
              <div className="row hard-center bg-white border-solid border-slate-300 rounded-lg px-7">
                <div className="row hard-center some-vertical-space">
                  <div
                    className="wistia_responsive_padding"
                    data-id="kui0x3sywv"
                    style={{ padding: "56.25% 0 0 0", position: "relative" }}
                  >
                    <div
                      className="wistia_responsive_wrapper"
                      style={{
                        height: "100%",
                        left: "0",
                        position: "absolute",
                        top: "0",
                        width: "100%",
                      }}
                      onLoad={() => {
                        $.getScript("https://fast.wistia.com/assets/external/E-v1.js");
                        $.getScript("https://fast.wistia.com/embed/medias/kui0x3sywv.jsonp");
                      }}
                    >
                      <div
                        className="wistia_embed wistia_async_kui0x3sywv popover=true videoFoam=true"
                        style={{
                          display: "inline-block",
                          height: "100%",
                          position: "relative",
                          width: "100%",
                        }}
                        ref={wistia}
                      >
                        <img
                          src="https://fast.wistia.com/embed/medias/kui0x3sywv/swatch"
                          style={{
                            filter: "blur(5px)",
                            height: "100%",
                            objectFit: "contain",
                            width: "100%",
                          }}
                          alt=""
                          aria-hidden="true"
                          onLoad={() => {
                            wistia.current.style.opacity = 1;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row hard-center">
                  <p className="bold">Unlock conditional rows</p>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Conditionally show content
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Personalize your website
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Streamline your workflow
                  </div>
                </div>
                <div className="row mb-5 mt-2">
                  <div className="col-xs-12">
                    <button
                      className="btn bg-black !text-white font-bold py-2 px-4"
                      onClick={(event) => {
                        subscriptions.upgrade_modal(
                          event,
                          false,
                          "personalize your website with conditional rows.",
                          "2024_plus",
                          "Triggered feature CTA – Conditional Rows"
                        );
                      }}
                    >
                      <svg
                        className="mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M3.30039 4.40019V3.94305C3.30039 2.4236 4.50468 1.2002 6.00039 1.2002C7.49611 1.2002 8.70039 2.4236 8.70039 3.94305V4.40019M3.30039 4.40019C2.80539 4.40019 2.40039 4.81162 2.40039 5.31448V9.88591C2.40039 10.3888 2.80539 10.8002 3.30039 10.8002H8.70039C9.19539 10.8002 9.60039 10.3888 9.60039 9.88591V5.31448C9.60039 4.81162 9.19539 4.40019 8.70039 4.40019M3.30039 4.40019H8.70039"
                          stroke="white"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                        />
                      </svg>
                      Upgrade To Unlock
                    </button>
                    <div className="text-xl mt-3">
                      <a
                        href="https://www.convertflow.com/personalization"
                        target="_blank"
                      >
                        Learn more →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default ElementSettingsConditionalRow;
