import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import StepBuilder from "../steps/StepBuilder";
import { functionsIn } from "lodash";
import useSteps from "../steps/useSteps.js";
import useSections from "../sections/useSections.js";
import useElements from "../elements/useElements.js";
import useFields from "../fields/useFields.js";
import useContentToggle from "../content_toggles/useContentToggle";
import VariantClose from "../variants/VariantClose";
import ZoomableCanvas from "../shared/ZoomableCanvas";
import VariantBuilderCanvasRoundedCorner from "../variants/VariantBuilderCanvasRoundedCorner";
import apiRequest from "../../utils/apiRequest";

const VariantBuilderCanvas = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { Steps } = useSteps();
  const { Sections } = useSections();
  const { Elements } = useElements(Sections);
  const { Fields } = useFields();
  const { ContentToggle } = useContentToggle(Sections);

  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let user = contextData.objects.users.filter((user) => {
    return user.id == variant.user_id;
  })[0];
  const sorted_steps = Object.values(variant.steps)
    .filter((variant) => variant.toBeDeleted !== true)
    .sort((a, b) => a.position - b.position);

  let cta_type_type = cta[cta.cta_type] ? cta[cta.cta_type] : "";

  function objectToUse(object_type, object_id) {
    return contextData.objects.variant[object_type][object_id];
  }

  function hookToUse(object_type) {
    let hookToUse;
    if (object_type == "steps") {
      hookToUse = Steps;
    }
    if (object_type == "sections") {
      hookToUse = Sections;
    }
    if (object_type == "elements") {
      hookToUse = Elements;
    }
    if (object_type == "fields") {
      hookToUse = Fields;
    }
    if (object_type == "content_toggle_items") {
      hookToUse = ContentToggle;
    }
    return hookToUse;
  }

  const handledSelect = function (event) {
    const details = event.detail;
    console.log("select", details);
    builder.select(
      details.object_type,
      details.object_id,
      details.tab,
      details.collapse,
      details.editing,
      details.panel_closed
    );

    if (details.editing == true) {
      const object = objectToUse(details.object_type, details.object_id);
      const hook = hookToUse(details.object_type);
      hook.froalaEditor(website, object, details.target);
    }

    if (details.object_type == "elements") {
      $(`.cf-element[data-object-type="elements"][data-element-id="${details.object_id}"]`).addClass("editor-select");
    }

    if (details.object_type == "sections") {
      $(`.cf-section[data-object-type="sections"][data-section-id="${details.object_id}"], .cf-section-overlay[data-section-id="${details.object_id}"]`).addClass("editor-select");
    }

    if (details.object_type == "steps") {
      $(`.canvas[data-step-id="${details.object_id}"]`).addClass("editor-select");
    }
  };

  const handleDuplicate = function (event) {
    const details = event.detail;
    const object = objectToUse(details.object_type, details.object_id);
    const hook = hookToUse(details.object_type);

    new Promise((resolve, reject) => {
      resolve(hook.duplicate(object));
    }).then((new_object) => {
      let target_object_type = details.object_type
      let target_object_id = new_object.id
      let target_collapse = undefined;
      let target_panel_closed = undefined;
      let target_tab =
        details.object_type == "steps"
          ? "Logic"
          : details.object_type == "sections"
          ? "Section"
          : "Content";

      if (new_object.element_type == "row") {
        target_collapse = "first";
        target_panel_closed = false;
        target_tab = "Columns";
        target_object_type = "sections";
        target_object_id = Object.values(contextData.objects.variant.sections).find(
          (section) => section.element_id == new_object.id
        ).id;
      } else if (details.object_type == "content_toggle_items") {
        target_object_type = "elements";
        target_object_id = new_object.element_id;
      }

      dispatchCustomEvent("selectObject", {
        object_type: target_object_type,
        object_id: target_object_id,
        tab: target_tab,
        collapse: target_collapse,
        panel_closed: target_panel_closed,
      });
    });
  };

  const handleMoveUp = function (event) {
    const details = event.detail;
    const object = objectToUse(details.object_type, details.object_id);
    const hook = hookToUse(details.object_type);
    hook.moveUp(object);
  };

  const handleMoveDown = function (event) {
    const details = event.detail;
    const object = objectToUse(details.object_type, details.object_id);
    const hook = hookToUse(details.object_type);
    hook.moveDown(object);
  };

  const handleRemove = function (event) {
    const details = event.detail;
    const object = objectToUse(details.object_type, details.object_id);
    const hook = hookToUse(details.object_type);
    hook.remove(object);
  };

  const handleUpdate = function (event) {
    const updates = event.detail.updates;
    if (updates) {
      builder.update(updates);
    }
  };

  const handleNewObject = function (event) {
    builder.new_object(event.detail);
  };

  const handleNavigation = function (event) {
    const details = event.detail;
    builder.view(details.view, details.collapse);
  };

  const handleFocus = function (event) {
    const details = event.detail;
    builder.focus(details.parent_id, details.item_type, details.item_id);
  };

  useEffect(() => {
    if (props.page == "builder") {
      Steps.sortableStart();

      let canvas = document.querySelector("#builder");

      canvas.onmouseover = function (e) {
        if (canvas.getAttribute("data-engaged") !== "true") {
          canvas.setAttribute("data-engaged", "true");
        }
      };

      canvas.onmouseleave = function (e) {
        if (canvas.getAttribute("data-engaged") == "true") {
          canvas.setAttribute("data-engaged", "false");
        }
      };

      document.addEventListener("selectObject", handledSelect);
      document.addEventListener("duplicateObject", handleDuplicate);
      document.addEventListener("moveObjectUp", handleMoveUp);
      document.addEventListener("moveObjectDown", handleMoveDown);
      document.addEventListener("removeObject", handleRemove);
      document.addEventListener("updateBuilder", handleUpdate);
      document.addEventListener("newObject", handleNewObject);
      document.addEventListener("panelView", handleNavigation);
      document.addEventListener("focusItem", handleFocus);

      $('.cf-draggable-creation-hover[data-object-type="Elements"]').each(
        (i, pseudoElement) => {
          $(pseudoElement).on("click", function () {
            const columnContainer = $(pseudoElement).parents(
              ".cf-cta-item-container"
            );
            const sectionId = $(columnContainer).attr("data-section-id");
            const column = $(columnContainer).attr("data-section-column");

            document.dispatchEvent(
              new CustomEvent("newObject", {
                detail: {
                  object_type: "Elements",
                  section_id: sectionId,
                  column: column,
                },
              })
            );
          });
        }
      );

      return function cleanup() {
        document.removeEventListener("selectObject", handledSelect);
        document.removeEventListener("duplicateObject", handleDuplicate);
        document.removeEventListener("moveObjectUp", handleMoveUp);
        document.removeEventListener("moveObjectDown", handleMoveDown);
        document.removeEventListener("removeObject", handleRemove);
        document.removeEventListener("updateBuilder", handleUpdate);
        document.removeEventListener("newObject", handleNewObject);
        document.removeEventListener("panelView", handleNavigation);
        document.removeEventListener("focusItem", handleFocus);
      };
    }
  });

  useEffect(() => {
    if (window.history && window.history.pushState && environment !== "test") {
      window.onpopstate = (event) => {
        // Possible scenarios:
        // when enters page, does nothing and click on browser back/forward button
        // when enters page, make changes and click on browser back/forward button
        // when enters page after clicking on browser back/forward button on another CTA, make changes and click on browser back/forward button
        if (event.state == "contextDataChanged") {
          if (
            contextData.history.unsaved == true ||
            contextData.history.unpublished == true
          ) {
            let confirmation =
              window.cfBackButtonPressed == true
                ? true
                : confirm(
                    "You have unsaved changes, are you sure want to leave?"
                  );

            if (
              confirmation == true &&
              window.cfReachedDifferentPath !== true
            ) {
              window.cfBackButtonPressed = true;
              history.back();
            }
          } else {
            history.back();
          }
        } else {
          let isPreviousTurbolinks = Object.keys(event.state).includes(
            "turbolinks"
          );
          let isPreviousPathEqualCurrent =
            document.location.pathname == window.cfLastLocationPath;

          if (!isPreviousPathEqualCurrent) {
            window.cfReachedDifferentPath = true;
          }

          if (
            (isPreviousTurbolinks && isPreviousPathEqualCurrent) ||
            (!isPreviousPathEqualCurrent &&
              window.cfReachedDifferentPath !== true)
          ) {
            history.back();
          }
        }
      };
    }
  }, []);

  useEffect(() => {
    // popstate just works with history.pushState
    window.cfLastLocationPath = location.pathname + location.search;
    window.cfBackButtonPressed = false;
    window.cfReachedDifferentPath = false;
    window.history.pushState("contextDataChanged", null, null);
    builder.unsavedWarning();

    window.onkeydown = function (e) {
      if (contextData.selected_object_editing !== true) {
        if (e.metaKey && e.keyCode == 83) {
          if (contextData.history.unsaved == true) {
            builder.save();
          }
          return false;
        }

        if (e.metaKey && e.shiftKey == false && e.keyCode == 90) {
          builder.undo();
        }

        if (e.metaKey && e.shiftKey == true && e.keyCode == 90) {
          builder.redo();
        }

        if (e.keyCode == 8) {
          let canvas = document.querySelector("#builder");
          const object_type = builder.selected().object_type;

          if (object_type && canvas.getAttribute("data-engaged") == "true") {
            hookToUse(object_type).remove(builder.selected().object);
          }
        }
      }
    };
  }, [contextData]);

  const canvasContainer = () => {
    return (
      <div className="h-screen flex-grow w-full">
        <div
          id="builder"
          cta-id={cta.id}
          cta-type={cta.cta_type}
          className={`variant-edit-canvas ${cta.cta_type} ${cta_type_type}`}
          style={{
            background:
              variant.options["container-background"] == "custom"
                ? variant.options["container-background-color"]
                : undefined,
          }}
        >
          <div
            id="canvas-container"
            className={"row hard-center canvas-container-" + contextData.device}
            data-editing={contextData.selected_object_editing}
          >
            <div
              id={"cta_" + cta.id}
              className={
                "steps-container cf-" + cta.cta_type + "-" + cta_type_type
              }
              data-cta-id={cta.id}
              data-variant-id={variant.id}
              data-variant-variation={variant.variation}
            >
              {sorted_steps.map((step) => {
                return (
                  <StepBuilder
                    key={"StepBuilder" + step.id}
                    className="step"
                    website={website}
                    cta={cta}
                    variant={variant}
                    user={user}
                    step={step}
                    builder={true}
                    editing={contextData.selected_object_editing}
                    device={contextData.device}
                    section_templates={props.section_templates}
                    {...props}
                  />
                );
              })}
            </div>
            <div
              onClick={() => {
                dispatchCustomEvent("newObject", {
                  object_type: "Steps",
                });
              }}
              className="row hard-center vertical-space step-add-container text-blue-600 dark:text-white"
            >
              <span
                className="glyphicon glyphicon-plus-sign"
                aria-hidden="true"
              ></span>{" "}
              Add Funnel Step
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {props.page == "builder" && (
        <React.Fragment>
          <ZoomableCanvas
            builder={true}
            initialScale={
              cta.cta_type == "page"
                ? window.innerWidth < 1200
                  ? 0.5
                  : window.innerWidth <= 1600
                  ? 0.75
                  : 1
                : window.innerWidth < 1440
                ? 0.75
                : 1
            }
            initialX={
              cta.cta_type == "page"
                ? window.innerWidth < 1200
                  ? contextData.zoomable_canvas_locked ? 150 : 400
                  : window.innerWidth <= 1600
                  ? contextData.zoomable_canvas_locked ? 150 : 300
                  : contextData.zoomable_canvas_locked ? 150 : 300
                : window.innerWidth < 1440
                ? contextData.zoomable_canvas_locked ? 150 : 300
                : contextData.zoomable_canvas_locked ? 150 : 200
            }
          >
            {canvasContainer()}
          </ZoomableCanvas>

          <div
            className="h-screen fixed right-0 bg-white dark:bg-slate-950 mt-[49px] pt-4 center smooth z-10"
            style={{
              width: "10px",
            }}
          >
            <VariantBuilderCanvasRoundedCorner
              vertical="top"
              horizontal="right"
            />
            <VariantBuilderCanvasRoundedCorner
              vertical="bottom"
              horizontal="right"
            />
          </div>
          <div
            className="w-screen fixed bottom-0 bg-white dark:bg-slate-950 center smooth z-10"
            style={{
              height: "10px",
            }}
          ></div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default VariantBuilderCanvas;
