import React, { useEffect, useState } from "react";
import Product from "../products/Product";

const ElementProducts = (props) => {
  const element = props.element;
  const classes = style.classes(element);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    props.variant,
    element
  );

  const productsLimit = element.options["products-limit"] || 3

  // Standard Products: Also used on live script to correctly render products
  let tmpProductsList = [];
  [...Array(parseInt(productsLimit || 3))].forEach((_, i) => {
    const placeholderLabel = ["Shopify", "ShopifyGraphql"].includes(
      element.options["products-source"]
    )
      ? "Shopify Product"
      : "Placeholder";

    let price = i + 1;
    let discountedPrice;

    if (element.options["products-discount-type"] == "amount") {
      const discountAmount =
        element.options["products-discount-amount"] || 10;

      discountedPrice = (price - discountAmount).toFixed(2);
    }

    if (element.options["products-discount-type"] == "percentage") {
      const discountAmount =
        element.options["products-discount-percentage"] || 10;

      discountedPrice = (
        price -
        price * (parseInt(discountAmount) * 0.01)
      ).toFixed(2);
    }

    if (element.options["products-discount-type"] == "detect") {
      discountedPrice = (price - price * (parseInt(5) * 0.01)).toFixed(2);
    }

    if (discountedPrice && discountedPrice <= 0) {
      discountedPrice = "0.00";
    }

    tmpProductsList.push({
      image: null,
      title: `${placeholderLabel} ${i + 1}`,
      price: price.toFixed(2),
      discounted_price: discountedPrice,
      product_variants: [],
      imageList: [],
      options: [{ id: '1', name: 'Options', values: [1,2,3]}],
    });
  });
  // End Standard Products

  const [productsList, setProductsList] = useState(tmpProductsList);

  const [reviewsList, setReviewsList] = useState([]);

  const loadShopifyGraphqlProducts = (integration, products) => {
    let shopifyProducts = [];
    let orderedProducts = products;

    if (element.options["shopify-products-source"] == "selected" && element.options["shopify-products-selected"]) {
      shopifyProducts = Object.values(orderedProducts).filter((product) =>
        element.options["shopify-products-selected"]
          .split(",")
          .includes(product.handle)
      );
    } else if (element.options["shopify-products-source"] == "filter") {
      const productsFilter = element.options["shopify-products-filter"];

      shopifyProducts = Object.values(orderedProducts).map((el) => {
        if (el.handle.includes(productsFilter) ||
          el.productType.includes(productsFilter) ||
          el.tags.includes(productsFilter) ||
          el.variants.some(variant => variant.title.includes(productsFilter))
        ) {
          return el
        }
      }).filter(el => el)
    } else if (element.options["shopify-products-source"] == "collection") {
      const collectionIds = element.options["shopify-products-collection"]?.split(",").filter(item => item) || [];
      const showUnavailable = element.options["shopify-products-unavailable"] == "true";

      shopifyProducts = Object.values(products).map((el) => {
        const productAvailable = el.available !== false || showUnavailable == true;

        if (productAvailable && el.collections.some(collection => collectionIds.includes(collection.handle))) {
          return el
        }
      }).filter(el => el)
    } else if (element.options["shopify-products-source"] == "current") {
      let shopifyProductHandles = [element.options["shopify-products-current-fallback"]];

      shopifyProducts = Object.values(products).map((el) => {
        if (shopifyProductHandles.some(handle => el.handle.includes(handle))) {
          return el
        }
      }).filter(el => el)
    }

    if (shopifyProducts.length > 0) {
      let sliced = element.options["shopify-products-source"] !== "selected" ?
        shopifyProducts.slice(0, productsLimit) : shopifyProducts
      setProductsList(sliced);
    } else {
      setProductsList(tmpProductsList)
    }
  };

  const fetchShopifyGraphql = (integration) => {
    if (integration) {
      const parsed_store_name = (
        integration.api_data?.api_url || integration.api_data?.website
      )
        ?.replace("http://", "")
        ?.replace("https://", "")
        ?.replace(".myshopify.com", "")
        ?.replace("/", "")
        ?.replace(" ", "");
      const shopifyClient = new ShopifyGraphqlClient({
        store_name: parsed_store_name,
        token: integration.api_data["storefront-token"],
      });

      new ProductsAdapterService(shopifyClient, "ShopifyGraphql")
        .getProducts({ sort: element.options["shopify-products-sorting"] })
        .then((response) => {
          window.cf_shopify_products = response;

          loadShopifyGraphqlProducts(integration, response);
        });
    }
  };

  const setupProductsFeed = (ignore_cache = false) => {
    if (props.builder == true) {
      if (element.options["products-source"] == "Shopify") {
        const integration = Object.values(props.website.integrations).filter(
          (i) => i.integration_type == "Shopify" && i.status == true
        )[0];

        if (integration && integration.api_data["products"]) {
          let shopifyProducts = [];
          let tmpProductsList = [];

          if (element.options["shopify-products-source"] == "selected") {
            if (element.options["shopify-products-selected"]) {
              shopifyProducts = Object.values(
                integration.api_data["products"]
              ).filter((product) =>
                element.options["shopify-products-selected"]
                  .split(",")
                  .includes(product["id"] + "")
              );
            }
          }

          if (shopifyProducts.length == 0) {
            shopifyProducts = tmpProductsList
          }

          if (
            !element.options["shopify-products-source"] ||
            ["filter", "related", "cart"].includes(
              element.options["shopify-products-source"]
            )
          ) {
            shopifyProducts = integration.api_data["products"].slice(
              0,
              parseInt(productsLimit)
            );
          }

          shopifyProducts.forEach((shopifyProduct) => {
            const productVariant = integration.api_data[
              "product_variants"
            ].filter(
              (productVariant) =>
                productVariant["product_id"] == shopifyProduct["product_id"]
            )[0];

            if (productVariant) {
              let productVariants;

              if (shopifyProduct.options) {
                productVariants = shopifyProduct.options.map(
                  (productOption) => {
                    return {
                      id: productOption,
                      title: productOption,
                    };
                  }
                );
              }

              let discountedPrice;

              if (element.options["products-discount-type"] == "amount") {
                const discountAmount =
                  element.options["products-discount-amount"] || 10;

                discountedPrice = (
                  productVariant.price - discountAmount
                ).toFixed(2);
              }

              if (element.options["products-discount-type"] == "percentage") {
                const discountAmount =
                  element.options["products-discount-percentage"] || 10;

                discountedPrice = (
                  productVariant.price -
                  productVariant.price * (parseInt(discountAmount) * 0.01)
                ).toFixed(2);
              }

              if (discountedPrice && discountedPrice <= 0) {
                discountedPrice = "0.00";
              }

              tmpProductsList.push({
                title: shopifyProduct.name,
                price: productVariant.price,
                product_variants: productVariants,
                discounted_price: discountedPrice,
              });
            }
          });

          if (tmpProductsList.length > 0) {
            setProductsList(tmpProductsList);
          }
        }
      } else if (element.options["products-source"] == "ShopifyGraphql") {
        let shopifyProducts = window.cf_shopify_products;

        const integration = Object.values(props.website.integrations).filter(
          (i) => i.integration_type == "Shopify" && i.status == true
        )[0];

        if (shopifyProducts && ignore_cache == false) {
          loadShopifyGraphqlProducts(integration, shopifyProducts);
        } else {
          fetchShopifyGraphql(integration);
        }
      } else {
        let products = Object.values(element.products)
          .filter(
            (product) =>
              product.element_id == element.id && product.toBeDeleted !== true
          )
          .sort((a, b) => {
            return a.position - b.position;
          });

        if (products.length == 0) {
          products = tmpProductsList;
        }

        if (props.builder !== true) {
          Object.values(element.product_recommendations).forEach(
            (product_recommendation) => {
              Object.values(product_recommendation.products)
                .sort((a, b) => {
                  return a.position - b.position;
                })
                .forEach((product) => {
                  products.push(product);
                });
            }
          );
        }

        const adapterService = new ProductsAdapterService(
          undefined,
          "Convertflow"
        );

        if (products.length > 0) {
          setProductsList(
            products.map((product) => adapterService.adaptProduct(product))
          );
        }
      }
    }
  }

  useEffect(() => {
    setupProductsFeed();
  }, [
    element.options["shopify-products-related-type"],
    element.options["shopify-products-filter"],
    element.options["shopify-products-collection"],
    element.options["shopify-products-selected"],
    element.options["products-source"],
    element.products
  ]);

  useEffect(() => { setupProductsFeed(true) }, [
    element.options["shopify-products-sorting"],
  ]);

  if (props.builder == true) {
    useEffect(() => {
      let productIds = productsList.map(prod => prod.id)

      if (element.options["products-reviews-source"] && productIds.filter(id => id != null).length > 0) {
        const integration = Object.values(props.website.integrations).find(
          (i) =>
            i.integration_type == element.options["products-reviews-source"] &&
            i.status == true
        );

        if (integration) {
          const client = new window[integration.integration_type + "Client"]({
            app_key: integration.api_data["app_key"],
          });

          new ProductsReviewsAdapterService(client, integration.integration_type)
            .getReviews(productIds)
            .then((response) => {
              setReviewsList(response);
            });
        } else {
          setReviewsList([]);
        }
      }
    }, [productsList, element.options["products-reviews-source"]]);
  }

  const layout = element.options["products-layout"]
    ? element.options["products-layout"]
    : "grid";

  let productsPerRow;
  if (layout === "list") {
    productsPerRow = 1;
  } else {
    productsPerRow = element.options["products-limit-row"]
      ? element.options["products-limit-row"]
      : 3;
  }

  const productsPerRowMobile = element.options["products-limit-row-mobile"]
    ? element.options["products-limit-row-mobile"]
    : 1;

  let productAllButton;
  if (
    element.options["products-source"]?.toLowerCase() !== "convertflow" &&
    element.options["products-button-cart-all"] == "true" &&
    element.options["confirmation-type"] == "cart"
  ) {
    let buttonAllText = element.options["products-button-cart-all-text"]
      ? element.options["products-button-cart-all-text"]
      : "<p>Add All To Cart</p>";

    productAllButton = (
      <div
        className="cf-row cf-outer-center cf-products-all-button-outer est"
        data-element-id={element.id}
      >
        <div
          className={classes.buttonAddAllClasses + " cf-merge-tag-allowed"}
          dangerouslySetInnerHTML={{
            __html:
              props.builder == true
                ? "<div class='froala-editor'>" + buttonAllText + "</div>"
                : buttonAllText,
          }}
        ></div>
      </div>
    );
  }

  const emptyMessage = element.options["products-empty-message"]
    ? element.options["products-empty-message"]
    : "<p>Sorry, there are no products to display</p>";

  return (
    <React.Fragment>
      <div
        className="cf-products-outer cf-products-element"
        data-layout={layout}
        data-products-per-row={productsPerRow}
        data-products-per-row-mobile={productsPerRowMobile}
        data-element-id={element.id}
      >
        {productsList
          .sort((a, b) => {
            return a.position - b.position;
          })
          .map((product, i) => {
            const productReviews = reviewsList.find(
              (review) => review.product_id == product.id
            );

            return (
              <Product
                productReviews={productReviews}
                key={`product-${Math.random().toString(16).slice(2)}-${i}`}
                layout={layout}
                product={product}
                classes={classes}
                {...props}
              />
            );
          })}
      </div>
      {productAllButton}
      <div
        className="cf-row cf-outer-center cf-products-empty-message cf-merge-tag-allowed"
        data-layout={layout}
        dangerouslySetInnerHTML={{ __html: emptyMessage }}
        data-element-id={element.id}
      />
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const style = {
  classes: (element) => {
    let options = element.options;


    let buttonClasses =
      "element-content cf-button cf-button-primary cf-text-element";

    buttonClasses +=
      " " +
      (options["button-size"] ? options["button-size"] : "cf-button-small");

    let buttonAddAllClasses =
      buttonClasses +
      `${
        options["products-add-all-btn-padding"]
          ? " " + options["products-add-all-btn-padding"]
          : options["button-size"]
          ? " " + options["button-size"]
          : " cf-button-small"
      }`;


    let buttonLayoutClasses =
      options["button-width"] && options["button-width"] !== "custom"
        ? options["button-width"]
        : !options["button-width"]
        ? "cf-full-width"
        : options["button-width"] == "custom"
        ? ""
        : "cf-full-width";

    buttonLayoutClasses +=
      " " +
      (options["products-layout"] !== "list" && options["button-alignment"]
        ? options["button-alignment"]
        : "");

    return {
      buttonClasses: buttonClasses,
      buttonAddAllClasses: buttonAddAllClasses,
      buttonLayoutClasses: buttonLayoutClasses,
    };
  },

  stylesheet: (builder, device, cta, variant, element) => {
    let options = element.options;

    let innerPadding;
    if (options["products-item-padding"]) {
      const paddingValue = parseInt(options["products-item-padding"]);
      innerPadding = `${paddingValue}px`;
    }

    let innerPaddingMobile;
    if (options["products-item-padding-mobile"]) {
      const paddingValueMobile = parseInt(
        options["products-item-padding-mobile"]
      );
      innerPaddingMobile = `${paddingValueMobile}px`;
    }

    let outerPadding;
    if (options["products-padding"]) {
      const paddingValue = parseInt(options["products-padding"]);
      outerPadding = `${paddingValue}px`;
    }

    let outerPaddingMobile;
    if (options["products-padding-mobile"]) {
      const paddingValueMobile = parseInt(options["products-padding-mobile"]);
      outerPaddingMobile = `${paddingValueMobile}px`;
    }

    let verticalMargin;
    if (options["products-margin-vertical"]) {
      const marginValue = parseInt(options["products-margin-vertical"]);
      verticalMargin = `${marginValue}px`;
    }

    let verticalMarginMobile;
    if (options["products-margin-vertical-mobile"]) {
      const marginValueMobile = parseInt(
        options["products-margin-vertical-mobile"]
      );
      verticalMarginMobile = `${marginValueMobile}px`;
    }

    let borderRadius = "";
    if (options["products-item-radius"] == "cf-not-rounded") {
      borderRadius = "0px";
    }
    if (options["products-item-radius"] == "cf-slightly-rounded") {
      borderRadius = "4px";
    }
    if (options["products-item-radius"] == "cf-fully-rounded") {
      borderRadius = "100px";
    }
    if (options["products-item-radius"] == "custom") {
      borderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          borderRadius += `${
            _.isNil(options["products-item-radius-" + side]) == false
              ? options["products-item-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let boxShadow;

    if (options["products-item-shadow"] == "None") {
      boxShadow = "none";
    }

    if (options["products-item-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["products-item-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["products-item-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (options["products-item-shadow-horizontal-distance"]
          ? `${options["products-item-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["products-item-shadow-vertical-distance"]
          ? `${options["products-item-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["products-item-shadow-blur-radius"]
          ? `${options["products-item-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["products-item-shadow-spread-radius"]
          ? `${options["products-item-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["products-item-shadow-color"]
          ? options["products-item-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    let buttonBorderRadius = "";
    if (options["button-radius"] == "cf-not-rounded") {
      buttonBorderRadius = "0px";
    }
    if (options["button-radius"] == "cf-slightly-rounded") {
      buttonBorderRadius = "4px";
    }
    if (options["button-radius"] == "cf-fully-rounded") {
      buttonBorderRadius = "100px";
    }
    if (options["button-radius"] == "custom") {
      buttonBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          buttonBorderRadius += `${
            _.isNil(options["button-radius-" + side]) == false
              ? options["button-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let buttonShadow;
    let buttonShadowHover;

    if (options["button-shadow"] == "None") {
      buttonShadow = "none";
    }

    if (options["button-shadow"] == "soft") {
      buttonShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["button-shadow"] == "hard") {
      buttonShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["button-shadow"] == "custom") {
      buttonShadow = "";
      buttonShadow +=
        (options["button-shadow-horizontal-distance"]
          ? `${options["button-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      buttonShadow +=
        (options["button-shadow-vertical-distance"]
          ? `${options["button-shadow-vertical-distance"]}px`
          : "0px") + " ";

      buttonShadow +=
        (options["button-shadow-blur-radius"]
          ? `${options["button-shadow-blur-radius"]}px`
          : "0px") + " ";

      buttonShadow +=
        (options["button-shadow-spread-radius"]
          ? `${options["button-shadow-spread-radius"]}px`
          : "0px") + " ";

      buttonShadow +=
        (options["button-shadow-color"]
          ? options["button-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      buttonShadowHover =
        buttonShadow +
        (options["button-hover-shadow-color"]
          ? options["button-hover-shadow-color"]
          : options["button-shadow-color"]
          ? options["button-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      buttonShadow += options["button-shadow-inset"] == "true" ? "inset" : "";
      buttonShadowHover +=
        options["button-shadow-inset"] == "true" ? "inset" : "";
    }

    let buttonFont;
    if (options["button-font"] && options["button-font"] !== "theme") {
      buttonFont = options["button-font"];
    }

    let buttonFontWeight;
    if (
      options["button-font-weight"] &&
      options["button-font-weight"] !== "theme"
    ) {
      buttonFontWeight = options["button-font-weight"];
    }

    let buttonFontSize;
    if (options["button-font-size"]) {
      buttonFontSize = options["button-font-size"];
    }

    let imageBorderRadius = "";
    if (options["products-image-corner-rounding"] == "cf-not-rounded") {
      imageBorderRadius = "0px";
    }
    if (options["products-image-corner-rounding"] == "cf-slightly-rounded") {
      imageBorderRadius = "4px";
    }
    if (options["products-image-corner-rounding"] == "cf-fully-rounded") {
      imageBorderRadius = "100px";
    }
    if (options["products-image-corner-rounding"] == "custom") {
      imageBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          imageBorderRadius += `${
            _.isNil(options["products-image-corner-" + side]) == false
              ? options["products-image-corner-" + side]
              : "4"
          }px `;
        }
      );
    }

    let imageBadgePosition;
    if (element.options["products-image-badge-placement"] == "top-right") {
      imageBadgePosition = "top: 0; right: 0;";
    } else if (
      element.options["products-image-badge-placement"] == "top-left"
    ) {
      imageBadgePosition = "top: 0; left: 0;";
    } else if (
      element.options["products-image-badge-placement"] == "bottom-right"
    ) {
      imageBadgePosition = "bottom: 0; right: 0;";
    } else if (
      element.options["products-image-badge-placement"] == "bottom-left"
    ) {
      imageBadgePosition = "bottom: 0; left: 0;";
    }

    let imageBadgeBackground =
      element.options["products-image-badge-background"];

    let imageBadgeCornerRounding;
    if (options["products-image-badge-corner-rounding"] == "cf-not-rounded") {
      imageBadgeCornerRounding = "0px";
    }
    if (
      options["products-image-badge-corner-rounding"] == "cf-slightly-rounded"
    ) {
      imageBadgeCornerRounding = "4px";
    }
    if (options["products-image-badge-corner-rounding"] == "cf-fully-rounded") {
      imageBadgeCornerRounding = "100px";
    }
    if (options["products-image-badge-corner-rounding"] == "custom") {
      imageBadgeCornerRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          imageBadgeCornerRounding += `${
            _.isNil(options["products-image-badge-corner-" + side]) == false
              ? options["products-image-badge-corner-" + side]
              : "4"
          }px `;
        }
      );
    }

    let imageBadgeFontFamily =
      element.options["products-image-badge-font-family"];
    let imageBadgeFontWeight =
      element.options["products-image-badge-font-family-weight"];
    let imageBadgeFontSize = element.options["products-image-badge-font-size"];
    let imageBadgeFontColor =
      element.options["products-image-badge-text-color"];

    const quantityPickerShowLabel = element.options["products-quantity-picker-show-label"] == "true" ? true : false;
    const variantPickerShowLabel = element.options["products-variant-picker-show-label"] == "true" ? true : false;

    const quantityPickerButtonMinimumWidth = element.options["products-quantity-picker-minimum-width"]
      ? element.options["products-quantity-picker-minimum-width"]
      : undefined

    const quantityPickerFontFamily = element.options["products-quantity-picker-font-family"]
    const quantityPickerFontWeight = element.options["products-quantity-picker-font-weight"]
    const quantityPickerFontSize = element.options["products-quantity-picker-font-size"]
    const quantityPickerFontColor = element.options["products-quantity-picker-text-color"]
    const quantityPickerBackgroundColor = element.options["products-quantity-picker-background-color"]
    const quantityPickerBorderStyle = element.options["products-quantity-picker-border-style"]
    const quantityPickerBorderColor = element.options["products-quantity-picker-border-color"]
    const quantityPickerBorderHoverColor = element.options["products-quantity-picker-border-hover-color"] || variant.options["products-quantity-picker-border-hover-color"]
    const quantityPickerBorderWidthLeft = element.options["products-quantity-picker-border-left"]
    const quantityPickerBorderWidthRight = element.options["products-quantity-picker-border-right"]
    const quantityPickerBorderWidthTop = element.options["products-quantity-picker-border-top"]
    const quantityPickerBorderWidthBottom = element.options["products-quantity-picker-border-bottom"]

    let pickerBorderRounding;
    if (element.options["products-quantity-picker-corner-rounding"] == "cf-not-rounded") {
      pickerBorderRounding = "0px";
    }
    if (element.options["products-quantity-picker-corner-rounding"] == "cf-slightly-rounded") {
      pickerBorderRounding = "4px";
    }
    if (element.options["products-quantity-picker-corner-rounding"] == "cf-fully-rounded") {
      pickerBorderRounding = "100px";
    }
    if (element.options["products-quantity-picker-corner-rounding"] == "custom") {
      pickerBorderRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          pickerBorderRounding += `${
            _.isNil(element.options["products-quantity-picker-corner-" + side]) == false
              ? element.options["products-quantity-picker-corner-" + side]
              : "4"
          }px `;
        }
      );
    }

    const variantPickerOptionsMinimumWidth = element.options["products-variant-picker-button-minimum-width"];
    const variantPickerFontFamily = element.options["products-variant-picker-font-family"];
    const variantPickerFontWeight = element.options["products-variant-picker-font-weight"];
    const variantPickerFontSize = element.options["products-variant-picker-font-size"];
    const variantPickerFontColor = element.options["products-variant-picker-text-color"];
    const variantPickerFontHoverColor = element.options["products-variant-picker-text-hover-color"] || variant.options["products-variant-picker-text-hover-color"];
    const variantPickerBorderStyle = element.options["products-variant-picker-border-style"];
    const variantPickerBorderColor = element.options["products-variant-picker-border-color"];
    const variantPickerBorderHoverColor = element.options["products-variant-picker-border-hover-color"] || variant.options["products-variant-picker-border-hover-color"];
    const variantPickerBorderWidthLeft = element.options["products-variant-picker-border-left"];
    const variantPickerBorderWidthRight = element.options["products-variant-picker-border-right"];
    const variantPickerBorderWidthTop = element.options["products-variant-picker-border-top"];
    const variantPickerBorderWidthBottom = element.options["products-variant-picker-border-bottom"];
    const variantPickerBackgroundColor = element.options["products-variant-picker-background-color"];
    const variantPickerBackgroundHoverColor = element.options["products-variant-picker-background-hover-color"] || variant.options["products-variant-picker-background-hover-color"];

    let variantPickerBorderRounding;
    if (element.options["products-variant-picker-corner-rounding"] == "cf-not-rounded") {
      variantPickerBorderRounding = "0px";
    }
    if (element.options["products-variant-picker-corner-rounding"] == "cf-slightly-rounded") {
      variantPickerBorderRounding = "4px";
    }
    if (element.options["products-variant-picker-corner-rounding"] == "cf-fully-rounded") {
      variantPickerBorderRounding = "100px";
    }
    if (element.options["products-variant-picker-corner-rounding"] == "custom") {
      variantPickerBorderRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          variantPickerBorderRounding += `${
            _.isNil(element.options["products-variant-picker-corner-" + side]) == false
              ? element.options["products-variant-picker-corner-" + side]
              : "1"
          }px `;
        }
      );
    }

    const subscriptionChoicesFontFamily = element.options["products-subscription-choices-font-family"];
    const subscriptionChoicesFontWeight = element.options["products-subscription-choices-font-weight"]
    const subscriptionChoicesFontSize = element.options["products-subscription-choices-font-size"] || variant.options["products-subscription-choices-font-size"];

    const subscriptionChoicesFontColor = element.options["products-subscription-choices-text-color"];
    const subscriptionChoicesFontHoverColor = element.options["products-subscription-choices-text-hover-color"];
    const subscriptionChoicesBackgroundColor = element.options["products-subscription-choices-background-color"];
    const subscriptionChoicesRadioColor = element.options["products-subscription-radio-color"];
    const subscriptionChoicesSelectedRadioColor = element.options["products-subscription-radio-selected-color"];

    let subscriptionChoicesBorderRounding;
    if (element.options["products-subscription-corner-rounding"] == "cf-not-rounded") {
      subscriptionChoicesBorderRounding = "0px";
    }
    if (element.options["products-subscription-corner-rounding"] == "cf-slightly-rounded") {
      subscriptionChoicesBorderRounding = "4px";
    }
    if (element.options["products-subscription-corner-rounding"] == "cf-fully-rounded") {
      subscriptionChoicesBorderRounding = "100px";
    }
    if (element.options["products-subscription-corner-rounding"] == "custom") {
      subscriptionChoicesBorderRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          subscriptionChoicesBorderRounding += `${
            _.isNil(element.options["products-subscription-corner-" + side]) == false
              ? element.options["products-subscription-corner-" + side]
              : "1"
          }px `;
        }
      );
    }

    const subscriptionChoicesDropdownBorderStyle = element.options["products-subscription-border-style"];
    const subscriptionChoicesDropdownBorderColor = element.options["products-subscription-border-color"];
    const subscriptionChoicesDropdownBorderHoverColor = element.options["products-subscription-border-hover-color"];
    const subscriptionChoicesDropdownBorderWidthLeft = element.options["products-subscription-border-left"];
    const subscriptionChoicesDropdownBorderWidthRight = element.options["products-subscription-border-right"];
    const subscriptionChoicesDropdownBorderWidthTop = element.options["products-subscription-border-top"];
    const subscriptionChoicesDropdownBorderWidthBottom = element.options["products-subscription-border-bottom"];
    const subscriptionChoicesShowLabel = element.options["products-subscription-show-label"] == "false" ? false : true;

    const contentTypeSpacing = element.options["products-content-type-spacing"];
    const productImageCarouselHeight = element.options["products-carousel-image-height"];

    let addAllButtonBorderRadius = "";
    if (options["products-add-all-btn-radius"] == "cf-not-rounded") {
      addAllButtonBorderRadius = "0px";
    }
    if (options["products-add-all-btn-radius"] == "cf-slightly-rounded") {
      addAllButtonBorderRadius = "4px";
    }
    if (options["products-add-all-btn-radius"] == "cf-fully-rounded") {
      addAllButtonBorderRadius = "100px";
    }
    if (options["products-add-all-btn-radius"] == "custom") {
      addAllButtonBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          addAllButtonBorderRadius += `${
            _.isNil(options["products-add-all-btn-radius-" + side]) == false
              ? options["products-add-all-btn-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let addAllButtonShadow;
    let addAllButtonShadowHover;

    if (options["products-add-all-btn-shadow"] == "None") {
      addAllButtonShadow = "none";
    }

    if (options["products-add-all-btn-shadow"] == "soft") {
      addAllButtonShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["products-add-all-btn-shadow"] == "hard") {
      addAllButtonShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["products-add-all-btn-shadow"] == "custom") {
      addAllButtonShadow = "";
      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-horizontal-distance"]
          ? `${options["products-add-all-btn-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-vertical-distance"]
          ? `${options["products-add-all-btn-shadow-vertical-distance"]}px`
          : "0px") + " ";

      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-blur-radius"]
          ? `${options["products-add-all-btn-shadow-blur-radius"]}px`
          : "0px") + " ";

      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-spread-radius"]
          ? `${options["products-add-all-btn-shadow-spread-radius"]}px`
          : "0px") + " ";

      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-color"]
          ? options["products-add-all-btn-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      addAllButtonShadowHover =
        addAllButtonShadow +
        (options["button-hover-shadow-color"]
          ? options["button-hover-shadow-color"]
          : options["products-add-all-btn-shadow-color"]
          ? options["products-add-all-btn-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      addAllButtonShadow += options["products-add-all-btn-shadow-inset"] == "true" ? "inset" : "";
      addAllButtonShadowHover +=
        options["products-add-all-btn-shadow-inset"] == "true" ? "inset" : "";
    }

    const reviewsShowCount = element.options["products-reviews-show-count"] !== "false";
    const reviewsShowLabel = element.options["products-reviews-show-label"] !== "false";
    const reviewsStarColor = element.options["products-reviews-star-color"];
    const reviewsStarSize = element.options["products-reviews-star-size"];
    const reviewsFontFamily = element.options["products-reviews-font-family"];
    const reviewsFontWeight = element.options["products-reviews-font-weight"];
    const reviewsFontSize = element.options["products-reviews-font-size"];
    const reviewsFontColor = element.options["products-reviews-text-color"];

    const productsContentLabelFontSize = options["products-content-label-font-size"];
    const productsContentLabelFontWeight = options["products-content-label-font-weight"];
    const productsContentLabelFontFamily = options["products-content-label-font-family"];
    const productsContentLabelLineHeight = options["products-content-label-line-height"];
    const productsContentLabelFontColor = options["products-content-label-font-color"];

    const desktopStyles =
      // prettier-ignore
      `
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-reviews {
  display: flex;
  gap: 10px;
  align-items: center;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-quantity-picker-label,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-variant-picker-label,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-label {
  ${productsContentLabelFontSize ? `font-size: ${productsContentLabelFontSize}px !important;` : ""}
  ${productsContentLabelFontWeight ? `font-weight: ${productsContentLabelFontWeight} !important;` : ""}
  ${productsContentLabelFontFamily ? `font-family: ${productsContentLabelFontFamily} !important;` : ""}
  ${productsContentLabelLineHeight ? `line-height: ${productsContentLabelLineHeight}px !important;` : ""}
  ${productsContentLabelFontColor ? `color: ${productsContentLabelFontColor} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-reviews-count {
  display: ${reviewsShowCount ? "block" : "none"};
  ${reviewsFontFamily ? `font-family: ${reviewsFontFamily} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-reviews-label {
  display: ${reviewsShowLabel ? "block" : "none"};
  ${reviewsFontFamily ? `font-family: ${reviewsFontFamily} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-reviews {
  ${reviewsFontWeight ? `font-weight: ${reviewsFontWeight} !important;` : ""}
  ${reviewsFontSize ? `font-size: ${reviewsFontSize}px !important;` : ""}
  ${reviewsFontColor ? `color: ${reviewsFontColor} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-reviews-stars-inner svg {
  ${reviewsStarColor ? `color: ${reviewsStarColor};` : ""}
}
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-reviews-stars-inner .svg-inline--fa {
  ${reviewsStarSize ? `font-size: ${reviewsStarSize}px;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list .cf-products-reviews-stars-inner svg {
  width: auto !important;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-quantity-picker-label {
  display: ${quantityPickerShowLabel ? "block" : "none"};
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-product__image {
  ${productImageCarouselHeight ? `height: ${productImageCarouselHeight}px;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-variant-picker-label {
  display: ${variantPickerShowLabel ? "block" : "none"};
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item--shopify,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item--shopify .cf-products-list {
  margin-bottom: 10px;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item--shopify .cf-products-item-title {
  margin-top: 10px;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item--shopify .cf-variant-picker-wrapper {
  margin-top: 10px;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-label {
  display: ${subscriptionChoicesShowLabel ? "block" : "none"} !important;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-radio-wrapper {
  display: inline-block;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-radio-wrapper p {
  font-size: 12px;
  display: flex;
  flex: 1;
  align-items: flex-start;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-product-content-type {
  ${contentTypeSpacing ? `margin-bottom: ${contentTypeSpacing}px !important;` : ""}
}

#cta_${cta.id} .cf-products-item .cf-form-2.cf-form-theme-default.cf-field-regular select {
  margin-bottom: 0px !important;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-product-content-type .cf-form-theme-default {
  max-width: initial;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element div.cf-products-subscription-dropdown-wrapper.cf-product-content-type > div > select,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-radio-wrapper label {
  ${subscriptionChoicesFontFamily ? `font-family: ${subscriptionChoicesFontFamily} !important;` : ""}
  ${subscriptionChoicesFontWeight ? `font-weight: ${subscriptionChoicesFontWeight} !important;` : ""}
  ${subscriptionChoicesFontSize ? `font-size: ${subscriptionChoicesFontSize}px !important;` : ""}
  ${subscriptionChoicesFontColor ? `color: ${subscriptionChoicesFontColor} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-radio-wrapper label {
  width: 100%;
  ${subscriptionChoicesFontHoverColor ? `&:hover { color: ${subscriptionChoicesFontHoverColor} !important; }` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element div.cf-products-subscription-dropdown-wrapper.cf-product-content-type > div > select {
  ${subscriptionChoicesBackgroundColor ? `background-color: ${subscriptionChoicesBackgroundColor} !important;` : ""}
  ${subscriptionChoicesDropdownBorderStyle ? `border-style: ${subscriptionChoicesDropdownBorderStyle} !important;` : ""}
  ${subscriptionChoicesDropdownBorderColor ? `border-color: ${subscriptionChoicesDropdownBorderColor} !important;` : ""}
  ${subscriptionChoicesDropdownBorderWidthLeft ? `border-left-width: ${subscriptionChoicesDropdownBorderWidthLeft}px !important;` : ""}
  ${subscriptionChoicesDropdownBorderWidthRight ? `border-right-width: ${subscriptionChoicesDropdownBorderWidthRight}px !important;` : ""}
  ${subscriptionChoicesDropdownBorderWidthTop ? `border-top-width: ${subscriptionChoicesDropdownBorderWidthTop}px !important;` : ""}
  ${subscriptionChoicesDropdownBorderWidthBottom ? `border-bottom-width: ${subscriptionChoicesDropdownBorderWidthBottom}px !important;` : ""}
  ${subscriptionChoicesBorderRounding ? `border-radius: ${subscriptionChoicesBorderRounding} !important;` : ""}
  ${subscriptionChoicesDropdownBorderHoverColor ? `&:hover { border-color: ${subscriptionChoicesDropdownBorderHoverColor} !important; }` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-radio-wrapper input[type="radio"] {
  ${subscriptionChoicesRadioColor ? `background-color: ${subscriptionChoicesRadioColor} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-radio-wrapper input[type="radio"]:checked {
  ${subscriptionChoicesSelectedRadioColor ? `background-color: ${subscriptionChoicesSelectedRadioColor} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element div.cf-variant-picker-wrapper.cf-product-content-type > div > select {
  border-radius: 3px;
  ${variantPickerBorderColor ? `border-color: ${variantPickerBorderColor} !important;` : ""}
  ${variantPickerFontFamily ? `font-family: ${variantPickerFontFamily} !important;` : ''}
  ${variantPickerFontWeight ? `font-weight: ${variantPickerFontWeight} !important;` : ""}
  ${variantPickerFontSize ? `font-size: ${variantPickerFontSize}px !important;` : ""}
  ${variantPickerFontColor ? `color: ${variantPickerFontColor} !important;` : ""}
  ${variantPickerBorderStyle ? `border-style: ${variantPickerBorderStyle} !important;` : ""}
  ${variantPickerBorderColor ? `border-color: ${variantPickerBorderColor} !important;` : ""}
  ${variantPickerBackgroundColor ? `background-color: ${variantPickerBackgroundColor} !important;` : ""}
  ${variantPickerBorderWidthLeft ? `border-left-width: ${variantPickerBorderWidthLeft}px !important;` : ""}
  ${variantPickerBorderWidthRight ? `border-right-width: ${variantPickerBorderWidthRight}px !important;` : ""}
  ${variantPickerBorderWidthTop ? `border-top-width: ${variantPickerBorderWidthTop}px !important;` : ""}
  ${variantPickerBorderWidthBottom ? `border-bottom-width: ${variantPickerBorderWidthBottom}px !important;` : ""}
  ${variantPickerBorderRounding ? `border-radius: ${variantPickerBorderRounding} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-option-item {
  ${variantPickerOptionsMinimumWidth ? `min-width: ${variantPickerOptionsMinimumWidth}px;` : ""}
  ${variantPickerFontFamily ? `font-family: ${variantPickerFontFamily};` : ""}
  ${variantPickerFontWeight ? `font-weight: ${variantPickerFontWeight};` : ""}
  ${variantPickerFontSize ? `font-size: ${variantPickerFontSize}px;` : ""}
  ${variantPickerFontColor ? `color: ${variantPickerFontColor};` : ""}
  ${variantPickerBorderStyle ? `border-style: ${variantPickerBorderStyle};` : ""}
  ${variantPickerBorderColor ? `border-color: ${variantPickerBorderColor};` : ""}
  ${variantPickerBackgroundColor ? `background-color: ${variantPickerBackgroundColor};` : ""}
  ${variantPickerBorderWidthLeft ? `border-left-width: ${variantPickerBorderWidthLeft}px;` : ""}
  ${variantPickerBorderWidthRight ? `border-right-width: ${variantPickerBorderWidthRight}px;` : ""}
  ${variantPickerBorderWidthTop ? `border-top-width: ${variantPickerBorderWidthTop}px;` : ""}
  ${variantPickerBorderWidthBottom ? `border-bottom-width: ${variantPickerBorderWidthBottom}px;` : ""}
  ${variantPickerBorderRounding ? `border-radius: ${variantPickerBorderRounding};` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element div.cf-variant-picker-wrapper.cf-product-content-type > div > select:hover {
  ${variantPickerFontHoverColor ? `color: ${variantPickerFontHoverColor} !important;` : "color: #fff;"}
  ${variantPickerBorderHoverColor ? `border-color: ${variantPickerBorderHoverColor} !important;` : "border-color: #fff;"}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-option-item:hover {
  ${variantPickerFontHoverColor ? `color: ${variantPickerFontHoverColor} !important;` : "color: #fff;"}
  ${variantPickerBorderHoverColor ? `border-color: ${variantPickerBorderHoverColor} !important;` : "border-color: #000;"}
  ${variantPickerBackgroundHoverColor ? `background-color: ${variantPickerBackgroundHoverColor} !important;` : "background-color: #000;"}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-button--selected {
  ${variantPickerFontHoverColor ? `color: ${variantPickerFontHoverColor} !important;` : "color: #fff;"}
  ${variantPickerBorderHoverColor ? `border-color: ${variantPickerBorderHoverColor} !important;` : ""}
  ${variantPickerBackgroundHoverColor ? `background-color: ${variantPickerBackgroundHoverColor} !important;` : "background-color: #000;"}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-quantity-picker-wrapper {
  ${quantityPickerButtonMinimumWidth ? `width: ${quantityPickerButtonMinimumWidth}px !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-increment-quantity-picker {
  ${quantityPickerFontWeight ? `font-weight: ${quantityPickerFontWeight} !important;` : ""}
  ${quantityPickerFontSize ? `font-size: ${quantityPickerFontSize}px !important;` : ""}
  ${quantityPickerFontColor ? `color: ${quantityPickerFontColor} !important;` : ""}
  ${quantityPickerBackgroundColor ? `background-color: ${quantityPickerBackgroundColor} !important;` : ""}
  ${quantityPickerBorderStyle ? `border-style: ${quantityPickerBorderStyle} !important;` : ""}
  ${quantityPickerBorderColor ? `border-color: ${quantityPickerBorderColor};` : ""}
  ${quantityPickerBorderWidthLeft ? `border-left-width: ${quantityPickerBorderWidthLeft}px !important;` : ""}
  ${quantityPickerBorderWidthRight ? `border-right-width: ${quantityPickerBorderWidthRight}px !important;` : ""}
  ${quantityPickerBorderWidthBottom ? `border-bottom-width: ${quantityPickerBorderWidthBottom}px !important;` : ""}
  ${quantityPickerBorderWidthTop ? `border-top-width: ${quantityPickerBorderWidthTop}px !important;` : ""}
  ${pickerBorderRounding ? `border-radius: ${pickerBorderRounding} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element a {
  text-decoration: none !important;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-increment-quantity-picker {
  ${quantityPickerBorderColor ? `border-color: ${quantityPickerBorderColor} !important;` : "border-color: #000;"}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-increment-quantity-picker .cf-decrement,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-increment-quantity-picker .cf-products-quantity,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-increment-quantity-picker .cf-increment {
  ${quantityPickerFontFamily ? `font-family: ${quantityPickerFontFamily} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-increment-quantity-picker:hover {
  ${quantityPickerBorderHoverColor ? `border-color: ${quantityPickerBorderHoverColor} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-outer {
  ${options["products-item-width"] ? 'max-width: ' + options["products-item-width"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item {
  ${outerPadding ? 'padding: ' + outerPadding + ' !important;' : ''}
  ${verticalMargin ? 'margin-bottom: ' + verticalMargin + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-inner {
  ${innerPadding ? 'padding: ' + innerPadding + ' !important;' : ''}
  ${options["products-item-background-color"] ? 'background: ' + options["products-item-background-color"] + ' !important;' : ''}
  ${options["products-item-border-style"] ? 'border-style: ' + options["products-item-border-style"] + '!important;' : ''}
  ${_.isNil(options["products-item-border-width"]) == false ? 'border-width: ' + options["products-item-border-width"] + 'px !important;' : ''}
  ${_.isNil(options["products-item-border-width-left"]) == false ? 'border-left-width: ' + options["products-item-border-width-left"] + 'px !important;' : ''}
  ${_.isNil(options["products-item-border-width-right"]) == false ? 'border-right-width: ' + options["products-item-border-width-right"] + 'px !important;' : ''}
  ${_.isNil(options["products-item-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["products-item-border-width-bottom"] + 'px !important;' : ''}
  ${_.isNil(options["products-item-border-width-top"]) == false ? 'border-top-width: ' + options["products-item-border-width-top"] + 'px !important;' : ''}
  ${options["products-item-border-color"] ? 'border-color: ' + options["products-item-border-color"] + ' !important;' : ''}
  ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-image img, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-image svg {
  ${options["products-item-image-height"] ? 'height: ' + options["products-item-image-height"] + 'px !important;' : ''}
  ${imageBorderRadius ? 'border-radius: ' + imageBorderRadius + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-image-wrapper .cf-products-item-badge {
  ${imageBadgePosition ? imageBadgePosition : ""}
  ${imageBadgeBackground ? `background: ${imageBadgeBackground} !important;` : ""}
  ${imageBadgeCornerRounding ? `border-radius: ${imageBadgeCornerRounding} !important;` : ''}
  ${imageBadgeFontFamily ? `font-family: ${imageBadgeFontFamily}, Helvetica, Arial, sans-serif !important;` : 'font-family: "Inter", Helvetica, sans-serif;'}
  ${imageBadgeFontWeight ? `font-weight: ${imageBadgeFontWeight} !important;` : ''}
  ${imageBadgeFontSize ? `font-size: ${imageBadgeFontSize}px !important;` : ''}
  ${imageBadgeFontColor ? `color: ${imageBadgeFontColor} !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list img:not(.cf-product__image),
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list svg {
  ${options["products-item-image-width"] ? 'width: ' + options["products-item-image-width"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list .cf-col-md-3 {
  ${options["products-item-image-width"] ? `width: calc(0% + ${options["products-item-image-width"]}px) !important` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list .cf-col-md-9 {
  ${options["products-item-image-width"] ? `width: calc(100% - ${options["products-item-image-width"]}px) !important` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-title p {
  ${options["products-item-title-size"] ? 'font-size: ' + options["products-item-title-size"] + 'px !important;' : ''}
  ${options["products-item-title-line-height"] ? 'line-height: ' + options["products-item-title-line-height"] + 'px !important;' : ''}
  ${options["products-item-title-font"] ? 'font-family: ' + options["products-item-title-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['products-item-title-font-weight'] ? 'font-weight: ' + options['products-item-title-font-weight'] + ' !important;' : ''}
  ${options["products-item-title-color"] ? 'color: ' + options["products-item-title-color"] + ' !important;' : ''}
  ${options["products-title-max-number-of-lines"] ? `-webkit-line-clamp: ${options["products-title-max-number-of-lines"]}; display: -webkit-box !important;` : ''}
  ${options["products-title-max-number-of-lines"] ? `line-clamp: ${options["products-title-max-number-of-lines"]};` : ''}
  ${options["products-title-max-number-of-lines"] ? `white-space: normal !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-price p {
  ${options["products-item-price-size"] ? 'font-size: ' + options["products-item-price-size"] + 'px !important;' : ''}
  ${options["products-item-price-font"] ? 'font-family: ' + options["products-item-price-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['products-item-price-font-weight'] ? 'font-weight: ' + options['products-item-price-font-weight'] + ' !important;' : ''}
  ${options["products-item-price-color"] ? 'color: ' + options["products-item-price-color"] + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-price[data-discount="true"] p {
  ${options["products-item-discount-color"] ? 'color: ' + options["products-item-discount-color"] + ' !important; opacity: 1;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-price[data-discount="true"] p strike {
  ${options["products-item-price-color"] ? 'color: ' + options["products-item-price-color"] + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description {
  ${options["products-description-max-number-of-lines"] ? `-webkit-line-clamp: ${options["products-description-max-number-of-lines"]};` : ''}
  ${options["products-description-max-number-of-lines"] ? `line-clamp: ${options["products-description-max-number-of-lines"]}; display: -webkit-box !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description p,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-empty-message p {
  ${options["products-item-description-size"] ? 'font-size: ' + options["products-item-description-size"] + 'px !important;' : ''}
  ${options["products-item-description-font"] ? 'font-family: ' + options["products-item-description-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['products-item-description-font-weight'] ? 'font-weight: ' + options['products-item-description-font-weight'] + ' !important;' : ''}
  ${options["products-item-description-color"] ? 'color: ' + options["products-item-description-color"] + ' !important;' : ''}
  ${options["products-item-description-line-height"] ? `line-height: ${options["products-item-description-line-height"]}px !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-button {
  ${options["button-color"] ? 'background: ' + options["button-color"] + '!important;' : ''}
  ${options["button-text-color"] ? 'color: ' + options["button-text-color"] + ' !important;' : ''}
  ${options["button-width"] == 'custom' && options["button-width-value"] ? 'min-width: ' + options["button-width-value"] + 'px !important;' : ''}
  ${options["button-size"] == 'custom' && options["button-size-value"] ? 'padding: ' + options["button-size-value"] + 'px 20px !important;' : ''}
  ${options["button-border-style"] ? 'border-style: ' + options["button-border-style"] + ' !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width"]) == false ? 'border-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-left"]) == false ? 'border-left-width: ' + options["button-border-width-left"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-left-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-right"]) == false ? 'border-right-width: ' + options["button-border-width-right"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-right-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-top"]) == false ? 'border-top-width: ' + options["button-border-width-top"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-top-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["button-border-width-bottom"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-bottom-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && options["button-border-color"] ? 'border-color: ' + options["button-border-color"] + ' !important;' : options["button-color"] ? 'border-color: ' + options["button-color"] + ' !important;' : ''}
  ${buttonBorderRadius ? 'border-radius: ' + buttonBorderRadius + ' !important;' : ''}
  ${buttonShadow ? 'box-shadow: ' + buttonShadow + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-all-button-outer .cf-button {
  ${options["products-add-all-btn-color"] ? `background-color: ${options["products-add-all-btn-color"]} !important;` : ""}
  ${options["products-add-all-btn-text-color"] ? `color: ${options["products-add-all-btn-text-color"]} !important;` : ""}
  ${options["products-add-all-btn-padding"] == 'custom' && options["products-add-all-btn-padding-value"] ? `padding: ${options["products-add-all-btn-padding-value"]}px 20px !important;` : ""}
  ${options["products-add-all-btn-border-style"] ? `border-style: ${options["products-add-all-btn-border-style"]} !important;` : ""}
  ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
  ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width-left"]) == false ? 'border-left-width: ' + options["products-add-all-btn-border-width-left"] + 'px !important;' : _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-left-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
  ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width-right"]) == false ? 'border-right-width: ' + options["products-add-all-btn-border-width-right"] + 'px !important;' : _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-right-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
  ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width-top"]) == false ? 'border-top-width: ' + options["products-add-all-btn-border-width-top"] + 'px !important;' : _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-top-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
  ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["products-add-all-btn-border-width-bottom"] + 'px !important;' : _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-bottom-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
  ${options["products-add-all-btn-border-style"] !== "" && options["products-add-all-btn-border-color"] ? 'border-color: ' + options["products-add-all-btn-border-color"] + ' !important;' : ''}
  ${addAllButtonBorderRadius ? `border-radius: ${addAllButtonBorderRadius} !important;` : ""}
  ${addAllButtonShadow ? `box-shadow: ${addAllButtonShadow} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-button:hover, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-button:active {
  ${options["button–hover-color"] ? 'background: ' + options["button–hover-color"] + ' !important;' : options["button-color"] ? 'background: ' + options["button-color"] + ' !important;' : ''}
  ${options["button–hover-text-color"] ? 'color: ' + options["button–hover-text-color"] + ' !important;' : options["button-text-color"] ? 'color: ' + options["button-text-color"] + ' !important;' : ''}
  ${options["button-hover-border-color"] ? 'border-color: ' + options["button-hover-border-color"] + ' !important;' : options["button-border-color"] ? 'border-color: ' +  options["button-border-color"] + ' !important;' : ''}
  ${buttonShadowHover ? 'box-shadow: ' + buttonShadowHover + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-all-button-outer .cf-button:hover, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-all-button-outer .cf-button:active {
  ${options["products-add-all-btn-hover-color"] ? `background-color: ${options["products-add-all-btn-hover-color"]} !important;` : ""}
  ${options["products-add-all-btn-hover-text-color"] ? `color: ${options["products-add-all-btn-hover-text-color"]} !important;` : ""}
  ${options["products-add-all-btn-hover-border-color"] ? `border-color: ${options["products-add-all-btn-hover-border-color"]} !important;` : ""}
  ${addAllButtonShadowHover ? `box-shadow: ${addAllButtonShadowHover} !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-button p {
  ${buttonFont ? `font-family: ${buttonFont} !important;` : ''}
  ${buttonFontWeight ? `font-weight: ${buttonFontWeight} !important;` : ''}
  ${buttonFontSize ? `font-size: ${buttonFontSize}px !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-all-button-outer .cf-button p {
  ${options["products-add-all-btn-font-family"] ? `font-family: ${options["products-add-all-btn-font-family"]} !important;` : ""}
  ${options["products-add-all-btn-font-weight"] ? `font-weight: ${options["products-add-all-btn-font-weight"]} !important;` : ""}
  ${options["products-add-all-btn-font-size"] ? `font-size: ${options["products-add-all-btn-font-size"]}px !important;` : ""}
}
`;

    let buttonFontSizeMobile;
    if (options["button-font-size-mobile"]) {
      buttonFontSizeMobile = options["button-font-size-mobile"];
    }

    let imageBadgeFontSizeMobile =
      element.options["products-image-badge-font-size-mobile"];

    const quantityPickerButtonMinimumWidthMobile = element.options["products-quantity-picker-minimum-width-mobile"]
      ? element.options["products-quantity-picker-minimum-width-mobile"]
      : undefined

    const quantityPickerFontSizeMobile = element.options["products-quantity-picker-font-size-mobile"];
    const variantPickerOptionsMinimumWidthMobile = element.options["products-variant-picker-button-minimum-width-mobile"];
    const variantPickerFontSizeMobile = element.options["products-variant-picker-font-size-mobile"];

    const contentTypeSpacingMobile = element.options["products-content-type-spacing-mobile"];

    const productImageCarouselHeightMobile = element.options["products-carousel-image-height-mobile"];

    const subscriptionChoicesFontSizeMobile = element.options["products-subscription-choices-font-size-mobile"] || variant.options["products-subscription-choices-font-size-mobile"];

    const reviewsStarSizeMobile = element.options["products-reviews-star-size-mobile"];
    const reviewsFontSizeMobile = element.options["products-reviews-font-size-mobile"];

    const productsContentLabelFontSizeMobile = options["products-content-label-font-size-mobile"];
    const productsContentLabelLineHeightMobile = options["products-content-label-line-height-mobile"];

    const mobileStyles =
      // prettier-ignore
      `/* mobile styles */
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-quantity-picker-wrapper {
  ${quantityPickerButtonMinimumWidthMobile ? `width: ${quantityPickerButtonMinimumWidthMobile}px !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-quantity-picker-label,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-variant-picker-label,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-label {
  ${productsContentLabelFontSizeMobile ? `font-size: ${productsContentLabelFontSizeMobile}px !important;` : ""}
  ${productsContentLabelLineHeightMobile ? `line-height: ${productsContentLabelLineHeightMobile}px !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-reviews {
  ${reviewsFontSizeMobile ? `font-size: ${reviewsFontSizeMobile}px !important;` : ""}
}
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-reviews-stars-inner .svg-inline--fa {
  ${reviewsStarSizeMobile ? `font-size: ${reviewsStarSizeMobile}px;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element div.cf-products-subscription-dropdown-wrapper.cf-product-content-type > div > select,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-subscription-radio-wrapper label {
  ${subscriptionChoicesFontSizeMobile ? `font-size: ${subscriptionChoicesFontSizeMobile}px !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-title p {
  ${options["products-title-max-number-of-lines-mobile"] ? `-webkit-line-clamp: ${options["products-title-max-number-of-lines-mobile"]}; display: -webkit-box !important;` : ''}
  ${options["products-title-max-number-of-lines-mobile"] ? `line-clamp: ${options["products-title-max-number-of-lines-mobile"]};` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description p {
  ${options["products-description-max-number-of-lines-mobile"] ? `-webkit-line-clamp: ${options["products-description-max-number-of-lines-mobile"]};` : ''}
  ${options["products-description-max-number-of-lines-mobile"] ? `line-clamp: ${options["products-description-max-number-of-lines-mobile"]}; display: -webkit-box !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description div,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description p,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-empty-message p {
  ${options["products-item-description-line-height-mobile"] ? `line-height: ${options["products-item-description-line-height-mobile"]}px !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-product-content-type {
  ${contentTypeSpacingMobile ? `margin-bottom: ${contentTypeSpacingMobile}px !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-product__image {
  ${productImageCarouselHeightMobile ? `height: ${productImageCarouselHeightMobile}px;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-increment-quantity-picker {
  ${quantityPickerFontSizeMobile ? `font-size: ${quantityPickerFontSizeMobile}px !important;` : ""}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element div.cf-variant-picker-wrapper.cf-product-content-type > div > select {
  ${variantPickerFontSizeMobile ? `font-size: ${variantPickerFontSizeMobile}px !important;` : "font-size: 12px;"}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-option-item {
  ${variantPickerOptionsMinimumWidthMobile ? `min-width: ${variantPickerOptionsMinimumWidthMobile}px !important;` : ""}
  ${variantPickerFontSizeMobile ? `font-size: ${variantPickerFontSizeMobile}px !important;` : ""}
}

#cta_${cta.id} .cf-products-outer[data-layout="grid"][data-products-per-row-mobile="1"] {
  grid-template-columns: 100% !important;
}

#cta_${cta.id} .cf-products-outer[data-layout="grid"][data-products-per-row-mobile="2"] {
  grid-template-columns: 50% 50% !important;
}

#cta_${cta.id} .cf-products-outer[data-layout="grid"][data-products-per-row-mobile="3"] {
  grid-template-columns: 33% 33% 33% !important;
}

#cta_${cta.id} .cf-products-outer[data-layout="grid"][data-products-per-row-mobile="4"] {
  grid-template-columns: 25% 25% 25% 25% !important;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-outer {
  ${options["products-item-width-mobile"] ? 'max-width: ' + options["products-item-width-mobile"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item {
  ${outerPaddingMobile ? 'padding: ' + outerPaddingMobile + ' !important;' : ''}
  ${verticalMarginMobile ? 'margin-bottom: ' + verticalMarginMobile + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-inner {
  ${innerPaddingMobile ? 'padding: ' + innerPaddingMobile + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-image img, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-image svg {
  ${options["products-item-image-height-mobile"] ? 'height: ' + options["products-item-image-height-mobile"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list img:not(.cf-product__image),
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list svg {
  ${options["products-item-image-width-mobile"] ? 'width: ' + options["products-item-image-width-mobile"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list .cf-col-md-3 {
  width: 100% !important;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list .cf-col-md-9 {
  width: 100% !important;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list .cf-products-item-image {
  margin-bottom: 15px;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-image-wrapper .cf-products-item-badge {
  ${imageBadgeFontSizeMobile ? `font-size: ${imageBadgeFontSizeMobile}px !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-list .cf-products-item-info {
  padding: 10px 0px;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-title p {
  ${options["products-item-title-size-mobile"] ? 'font-size: ' + options["products-item-title-size-mobile"] + 'px !important;' : ''}
  ${options["products-item-title-line-height-mobile"] ? 'line-height: ' + options["products-item-title-line-height-mobile"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-price p {
  ${options["products-item-price-size-mobile"] ? 'font-size: ' + options["products-item-price-size-mobile"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-item-description p,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-products-empty-message p {
  ${options["products-item-description-size-mobile"] ? 'font-size: ' + options["products-item-description-size-mobile"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-element .cf-button {
  ${options["button-width"] == 'custom' && options["button-width-value-mobile"] ? `min-width: ${options["button-width-value-mobile"]}px !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-button p,
#cta_${cta.id} .cf-button[data-element-id="${element.id}"] p {
  ${buttonFontSizeMobile ? `font-size: ${buttonFontSizeMobile}px !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-all-button-outer .cf-button p,
#cta_${cta.id} .cf-products-all-button-outer .cf-button[data-element-id="${element.id}"] p {
  ${options["products-add-all-btn-font-size-mobile"] ? `font-size: ${options["products-add-all-btn-font-size-mobile"]}px !important;` : ""}
}
`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementProducts;
