import React, { useRef, useState } from "react";
import actionsArray from "../../utils/actionsArray";
import conditionsArray from "../../utils/conditionsArray";
import ConditionListItem from "./ConditionListItem";
import ConditionForm from "./ConditionForm";
import useConditions from "./useConditions";
import useActionGroups from "../action_groups/useActionGroups";
import useBroadcasts from "../broadcasts/useBroadcasts";
import CtaAreasSelector from "../ctas/CtaAreasSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConditionsBuilder = (props) => {
  const { currentActionGroupsHook } = useActionGroups(props);
  const ActionGroups = currentActionGroupsHook;

  const { currentBroadcastsHook } = useBroadcasts(props);
  const Broadcasts = currentBroadcastsHook;

  const { currentConditionsHook } = useConditions(props);
  const Conditions = currentConditionsHook;

  const cta = props.cta;
  const variant = props.variant;
  const website = props.website;
  const broadcast = props.broadcast;

  let conditions = [];

  if (props.contactable_type == "Broadcast") {
    conditions = (broadcast && broadcast.conditions) || (website && website.conditions);
  } else if (props.contactable_type == "ConditionsGroup") {
    conditions = website && website.conditions;
  } else {
    conditions = variant && variant.conditions;
  }

  const [existingConditions, setExistingConditions] = useState(
    Object.values(conditions).filter(
      (c) =>
        c.contactable_type == props.contactable_type &&
        c.contactable_id == props.contactable_id &&
        c.toBeDeleted !== true &&
        !c.area_id
    )
  );

  const conditionsToUse =
    props.builder == false
      ? existingConditions
      : Object.values(conditions).filter(
          (c) =>
            c.contactable_type == props.contactable_type &&
            c.contactable_id == props.contactable_id &&
            c.toBeDeleted !== true
        );

  let actions = broadcast ? broadcast.actions : variant ? variant.actions : [];

  const existing_actions = Object.values(actions).filter(
    (action) =>
      action.contactable_type == props.contactable_type &&
      action.contactable_id == props.contactable_id &&
      action.toBeDeleted !== true
  );

  const actionsList = actionsArray(
    website,
    website?.integrations,
    existing_actions
  );

  const conditionsList = conditionsArray(
    variant,
    cta,
    website,
    props.contactable_id,
    props.contactable_type
  );

  const hookToUse = () => {
    let hookToUse;

    if (props.contactable_type == "ActionGroup") {
      hookToUse = ActionGroups;
    }
    if (props.contactable_type == "Broadcast") {
      hookToUse = Broadcasts;
    }

    return hookToUse;
  };

  const nestedCtaConditions = () => {
    if (
      props.contactable_type == "Broadcast" &&
      props.controller_name == "broadcasts"
    ) {
      let existingBroadcastCtaIds = Object.values(broadcast.ctas).map(
        (c) => c.id
      );

      if (
        typeof window !== "undefined" &&
        window.broadcast &&
        window.broadcast.ctas
      ) {
        existingBroadcastCtaIds = window.broadcast.ctas;
      }

      return (
        <React.Fragment>
          {broadcast &&
            existingBroadcastCtaIds.map((cta_id) => {
              const cta = website.ctas[cta_id];
              if (cta.cta_type == "page") {
                return;
              }
              const cta_conditions = Object.values(conditionsToUse).filter(
                (c) => c.cta_id == cta.id
              );
              return (
                <React.Fragment key={`nested-condition-${cta_id}`}>
                  <div
                    className="row hard-center condition-level"
                    style={{ display: "block" }}
                    data-cta-id={cta.id}
                  >
                    <div className="row hard-center some-vertical-space !mb-0">
                      <p className="smooth">
                        Show "
                        <b>
                          {cta.name +
                            " (" +
                            cta.cta_type.replace("inline", "embed") +
                            ")" || cta.cta_type + " Campaign " + cta.id}
                        </b>
                        " via Broadcast
                      </p>
                    </div>
                    {conditionItems(cta_conditions, cta.id)}
                    <CtaAreasSelector
                      contactable_id={props.contactable_id}
                      contactable_type={props.contactable_type}
                      broadcast={broadcast}
                      cta={cta}
                      website={website}
                    />
                  </div>
                </React.Fragment>
              );
            })}
        </React.Fragment>
      );
    }
  };

  const conditionItems = (conditionsToUse, cta_id) => {
    const existing_or_conditions = Object.values(conditionsToUse).filter(
      (c) => c.operator == "or"
    );
    const existing_and_conditions = Object.values(conditionsToUse).filter(
      (c) => c.operator == "and"
    );

    return (
      <React.Fragment>
        <div className="row hard-center conditions-container conditions-and-container">
          <div className="row hard-center conditions-container-label">
            <span className="text-xl text-black dark:text-white">
              <span className="conditions-if-message">If</span>{" "}
              <span className="semibold">ALL</span> of these conditions are met:
            </span>
          </div>
        </div>

        <div
          className="conditions"
          style={{ display: "flex", flexWrap: "wrap", maxWidth: broadcast && broadcast.quick != true ? '550px' : '600px' }}
        >
          {existing_and_conditions.map((condition, i) => {
            return (
              <React.Fragment key={`${condition.id}-${i}`}>
                <div
                  style={{ display: "contents" }}
                  className="condition-item"
                  data-id={condition.id}
                >
                  <ConditionListItem
                    object={condition}
                    actionsList={actionsList}
                    conditionsList={conditionsList}
                    conditionSelectOptions={conditionSelectOptions()}
                    onUpdate={(data) => {
                      if (props.builder == false) {
                        let items = [...existingConditions];
                        let targetIndex = items.findIndex(
                          (c) => c.id == data.id
                        );
                        let item = { ...items[targetIndex] };
                        item = data;
                        items[targetIndex] = item;
                        setExistingConditions(items);
                      }
                    }}
                    onDuplicate={(object) => {
                      Conditions.duplicate(object, {}, false, (data) => {
                        if (props.builder == false) {
                          setExistingConditions((conditions) => [
                            ...conditions,
                            data,
                          ]);
                        }
                      });
                    }}
                    onRemove={(object_id) => {
                      if (props.builder == false) {
                        setExistingConditions(
                          existingConditions.filter((c) => c.id !== object_id)
                        );
                      }
                    }}
                    {...props}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>

        <div className="row hard-center mb-5">
          {showAndConditionsForm ? (
            <ConditionForm
              broadcast={broadcast}
              actionsList={actionsList}
              conditionsList={conditionsList}
              conditionSelectOptions={conditionSelectOptions()}
              operator={"and"}
              onClose={() => setShowConditionsForm(false)}
              onCreate={(data) => {
                if (props.builder == false) {
                  setExistingConditions((conditions) => [...conditions, data]);
                }
                setAndShowConditionsForm(false);
              }}
              cta_id={cta_id}
              {...props}
            />
          ) : (
            <button
              className="bg-white dark:bg-slate-800 text-black dark:text-white py-2 px-4 text-center rounded-lg border-solid border-[1px] border-slate-200 dark:border-slate-700 text-xl"
              onClick={() => setAndShowConditionsForm(!showAndConditionsForm)}
              title="Add Condition"
            >
              <FontAwesomeIcon icon="fas fa-plus" className="mr-1" /> AND
            </button>
          )}
        </div>

        <div className="row hard-center conditions-container conditions-or-container">
          <div
            className="row hard-center conditions-container-label"
            style={{
              display: "inline-block",
              paddingTop: "10px",
            }}
          >
            <span className="text-xl text-black dark:text-white">
              {existing_and_conditions.length > 0 ? "And if" : "If"}{" "}
              <span className="semibold">ANY</span> of these conditions are met:
            </span>
          </div>
        </div>

        <div
          className="conditions"
          style={{ display: "flex", flexWrap: "wrap", maxWidth: broadcast && broadcast.quick != true ? '500px' : '600px' }}
        >
          {existing_or_conditions.map((condition, i) => {
            return (
              <React.Fragment key={`${condition.id}-${i}`}>
                <ConditionListItem
                  object={condition}
                  actionsList={actionsList}
                  conditionsList={conditionsList}
                  conditionSelectOptions={conditionSelectOptions()}
                  onUpdate={(data) => {
                    if (props.builder == false) {
                      let items = [...existingConditions];
                      let targetIndex = items.findIndex((c) => c.id == data.id);
                      let item = { ...items[targetIndex] };
                      item = data;
                      items[targetIndex] = item;
                      setExistingConditions(items);
                    }
                  }}
                  onDuplicate={(object) => {
                    Conditions.duplicate(object, {}, false, (data) => {
                      if (props.builder == false) {
                        setExistingConditions((conditions) => [
                          ...conditions,
                          data,
                        ]);
                      }
                    });
                  }}
                  onRemove={(object_id) => {
                    if (props.builder == false) {
                      setExistingConditions(
                        existingConditions.filter((c) => c.id !== object_id)
                      );
                    }
                  }}
                  {...props}
                />
              </React.Fragment>
            );
          })}
        </div>

        <div className="row hard-center mb-5">
          {showOrConditionsForm ? (
            <ConditionForm
              broadcast={broadcast}
              actionsList={actionsList}
              conditionsList={conditionsList}
              conditionSelectOptions={conditionSelectOptions()}
              operator={"or"}
              onClose={() => setShowConditionsForm(false)}
              onCreate={(data) => {
                if (props.builder == false) {
                  setExistingConditions((conditions) => [...conditions, data]);
                }
                setOrShowConditionsForm(false);
              }}
              cta_id={cta_id}
              {...props}
            />
          ) : (
            <button
              className="bg-white dark:bg-slate-800 text-black dark:text-white py-2 px-4 text-center rounded-lg border-solid border-[1px] border-slate-200 dark:border-slate-700 text-xl"
              onClick={() => setOrShowConditionsForm(!showOrConditionsForm)}
              title="Add Condition"
            >
              <FontAwesomeIcon icon="fas fa-plus" className="mr-1" /> OR
            </button>
          )}
        </div>
      </React.Fragment>
    );
  };

  const conditionSelectOptions = () => {
    let groups = Object.values(conditionsList).map((c) => c.group);
    let uniq_groups = groups.filter(
      (value, index, array) => groups.indexOf(value) === index
    );

    let selectOptions = [
      {
        label: "Select a condition type",
        value: "Select a condition type",
        options: [],
        isDisabled: true,
      },
    ];

    uniq_groups.forEach((group) => {
      const group_conditions = Object.values(conditionsList).filter(
        (c) => c.group == group && c.hidden !== true
      );

      let groupOption = { label: group, options: [] };

      group_conditions.forEach((condition) => {
        let condition_name = Object.entries(conditionsList).find(
          (a) => a[1] == condition
        )[0];

        groupOption.options.push({
          label: condition_name,
          value: condition_name,
          settings: condition.settings,
          operator: condition.operator,
          icon: condition.icon ? `/images/${condition.icon}` : null,
        });
      });

      selectOptions.push(groupOption);
    });

    return selectOptions;
  };

  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalObject, setModalObject] = useState(null);
  const [showAndConditionsForm, setAndShowConditionsForm] = useState(false);
  const [showOrConditionsForm, setOrShowConditionsForm] = useState(false);
  const [elementReloader, setElementReloader] = useState(false);

  const conditionsModalRef = useRef(null);

  return (
    <React.Fragment>
      <div className="col-xs-12 action-group-conditions">
        <div
          className="row hard-center condition-level"
          data-condition-level="default"
          style={{ display: "block" }}
        >
          <div>
            {conditionsToUse.length == 0 && (
              <div className="row hard-center p-5 text-black dark:text-white text-xl border-solid border-[1px] border-black dark:border-slate-800 my-5">
                No conditions added yet. Add a condition below!
              </div>
            )}

            {conditionItems(
              Object.values(conditionsToUse).filter((c) => !c.cta_id)
            )}

            {nestedCtaConditions()}
          </div>
        </div>
      </div>

      <div
        className="cf-conditions-group-settings-reloader"
        style={{ display: "none" }}
        onClick={() => setElementReloader(!elementReloader)}
      >
        {" "}
      </div>
    </React.Fragment>
  );
};

export default ConditionsBuilder;
